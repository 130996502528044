import {
  CONNECT_ACCOUNTS,
  LOAD_ACCOUNT_LAYOUT,
  LOAD_ACCOUNT_LAYOUT_FAILURE,
  LOAD_ACCOUNT_LAYOUT_SUCCESS,
  REMOVE_ACCOUNT,
  SET_ERROR_MESSAGE,
  SET_MODAL_ERROR_MESSAGE,
  SET_TAB,
  STORE_FORM_DATA,
  TOGGLE_CAPSULE,
  TOGGLE_CREDENTIALS_MODAL,
  TOGGLE_VERIFICATION_MODAL,
  SET_TOAST,
  SET_FLINKS_MOODAL,
  SET_FLINKS_LOADER,
  SET_TRANSFER_FUNDS_LOADER,
  TOGGLE_SIDEPANEL,
  TOGGLE_DOWNLOAD,
  LOAD_USER_DOCUMENTS,
  SET_INITIALVALUES,
  SET_CUSTOMER_TYPE
} from './constants';
export function setInitialValues(initialValues) {
  return {
    type: SET_INITIALVALUES,
    initialValues
  };
}

export function setCustomerType(customerType) {
  return {
    type: SET_CUSTOMER_TYPE,
    customerType
  };
}

export function toggleSidepanel(sidepanel, sidepanelContent) {
  return {
    type: TOGGLE_SIDEPANEL,
    sidepanel,
    sidepanelContent,
  };
}
export function loadUserDocuments(documents) {
  return {
    type: LOAD_USER_DOCUMENTS,
    documents,
  };
}

export function setTransferFundsLoader(transferFundsLoader) {
  return {
    type: SET_TRANSFER_FUNDS_LOADER,
    transferFundsLoader,
  };
}

export function setFlinksLoader(flinksLoader) {
  return {
    type: SET_FLINKS_LOADER,
    flinksLoader,
  };
}

export function setFlinksModal(flinksModal) {
  return {
    type: SET_FLINKS_MOODAL,
    flinksModal,
  };
}

export function storeFormData(formData) {
  return {
    type: STORE_FORM_DATA,
    formData,
  };
}

export function setAccountPageToast(toast) {
  return {
    type: SET_TOAST,
    toast,
  };
}

export function setErrorMessage(error) {
  return {
    type: SET_ERROR_MESSAGE,
    error,
  };
}

export function setModalErrorMessage(error) {
  return {
    type: SET_MODAL_ERROR_MESSAGE,
    error,
  };
}

export function toggleDownload(document) {
  return {
    type: TOGGLE_DOWNLOAD,
    document
  };
}

export function setActiveTab(key) {
  return {
    type: SET_TAB,
    key,
  };
}
export function toggleCapsule(activeCapsule) {
  return {
    type: TOGGLE_CAPSULE,
    activeCapsule,
  };
}
export function loadAccountLayout() {
  return {
    type: LOAD_ACCOUNT_LAYOUT,
  };
}
export function loadAccountLayoutSuccess(data) {
  return {
    type: LOAD_ACCOUNT_LAYOUT_SUCCESS,
    data,
  };
}
export function loadAccountLayoutFailure() {
  return {
    type: LOAD_ACCOUNT_LAYOUT_FAILURE,
  };
}
export function toggleVerificationModal(verificationModal) {
  return {
    type: TOGGLE_VERIFICATION_MODAL,
    verificationModal,
  };
}

export function toggleCredentialsModal(visible) {
  return {
    type: TOGGLE_CREDENTIALS_MODAL,
    credentialsModal: visible,
  };
}

export function connectAccounts(data) {
  return {
    type: CONNECT_ACCOUNTS,
    data
  };
}

export function removeAccount(id) {
  return {
    type: REMOVE_ACCOUNT,
    id
  };
}
