/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
// import FontFaceObserver from 'fontfaceobserver';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';

import './sass/index.scss';
import './shared/css/index.scss';

import 'global-styles';
import { loadableReady } from '@loadable/component';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import '!file-loader?name=[name].[ext]!./images/icon-72x72.png';
import '!file-loader?name=[name].[ext]!./images/icon-96x96.png';
import '!file-loader?name=[name].[ext]!./images/icon-120x120.png';
import '!file-loader?name=[name].[ext]!./images/icon-128x128.png';
import '!file-loader?name=[name].[ext]!./images/icon-144x144.png';
import '!file-loader?name=[name].[ext]!./images/icon-152x152.png';
import '!file-loader?name=[name].[ext]!./images/icon-167x167.png';
import '!file-loader?name=[name].[ext]!./images/icon-180x180.png';
import '!file-loader?name=[name].[ext]!./images/icon-192x192.png';
import '!file-loader?name=[name].[ext]!./images/icon-384x384.png';
import '!file-loader?name=[name].[ext]!./images/icon-512x512.png';
import '!file-loader?name=[name].json!./manifest.notajson';

import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions
// import '!!style-loader!css-loader!./shared/css/index.css';
import configureStore from './configureStore';

// Import i18n messages
import { appLocales, translationMessages as messages } from './i18n';

import renderInBrowser from './renderInBrowser';
import Routes from './routes';
// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)

// const openSansObserver = new FontFaceObserver('geomanistregular', {});
// const openSansObserver1 = new FontFaceObserver('geomanistbook', {});
// const openSansObserver2 = new FontFaceObserver('geomanistlight', {});
// const openSansObserver3 = new FontFaceObserver('geomanist', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
// Promise.all([
//   openSansObserver2.load(),
//   openSansObserver1.load(),
//   openSansObserver.load(),
// ]).then(() => {
//   document.body.classList.add('fontLoaded');
// });

// Create redux store with history
// const initialState = {};
// Create redux store with history
const initialState = window.APP_STATE || {};

// but, really, when we render in the browser, we want to do it with the initialState that we get from hydrating!

export const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

// console.log(`app.js!`);
// loadableReady(() => {
//   renderInBrowser({ messages, store, Routes, history });
// });
function getSubdomain() {
  const allowedSubdomain = ['abcd', 'portal', 'wxyz', 'altrust'];
  const { hostname } = window.location;

  // Split the hostname by periods
  const parts = hostname.split('.');

  // Check if there's a subdomain
  if (parts.length > 2) {
    const hasSubDomain = allowedSubdomain.includes(parts[0]);

    // Subdomain is the first part
    return hasSubDomain ? parts[0] : null;
  }
  // No subdomain
  return null;
}
function render() {
  
  const routesBasedOnSubDomain =
    getSubdomain() !== null
      ? Routes[0].subDomainRoutes[getSubdomain()]
      : Routes;

  renderInBrowser({ messages, store, Routes: routesBasedOnSubDomain, history });
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(messages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        // import('intl/locale-data/jsonp/de.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(messages))
    .catch(err => {
      throw err;
    });
} else {
  render(messages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
