/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import Panel from 'components/Panel';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import OrderBox from './orderbox';
import PreviousOrder from './previousorder';
import { formatters } from '../../../../utils/formatters';

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow: hidden;
  min-height: 0;
  padding-bottom: 32px;
  @media (max-width: 767px) {
    overflow: auto;
  }
`;

let RightPanel = ({
  rawdata,
  toggleRight,
  isRightOpen,
  toggleStage,
  activeStage,
  submitReviewOrder,
  submitForm,
  // modalContent,
  account,
  // ipoDetails,
  symbol,
  reviewOrder,
  primarytraderequests,
  revieworderError,
  financialfields,
  tradingFee,
  redirect,
  registrationStatus,
  investorType,
  investorName,
  loader,
  tags,
  investmentTotal,
  locale,
  numberOfUnits,
  totalTax,
  propertyDetail,
  propertyContent
}) => {
  return (
    <Col md={5} lg={5} xl={4} className="mb-5 mt-9">
      <div className="mt-lg-6 mt-md-7 pt-lg-2 pt-md-n1"></div>
      {/* RIGHT PANEL */}
      <Panel
        isOpen={isRightOpen}
        onToggle={toggleRight}
        // showPanelClose={rightPanelStage === 'buy-sell'}
      >
        <ScrollableContent className="pt-0 pt-md-7">
          {rawdata && (
            <OrderBox
              rawdata={
                activeStage
                  ? rawdata.stage_section.find(
                      item => item.stagename === activeStage,
                    )
                  : rawdata.stage_section.find(
                      item => item.stagename === rawdata.default_stage,
                    )
              }
              errorlookup={rawdata.errorlookup}
              account={account}
              tabs={rawdata.stage_section}
              toggleStage={toggleStage}
              toggleRight={toggleRight}
              // modalContent={modalContent}
              submitForm={submitForm}
              // ipoDetails={ipoDetails}
              symbol={symbol}
              submitReviewOrder={submitReviewOrder}
              reviewOrder={reviewOrder}
              revieworderError={revieworderError}
              tags={tags}
              financialfields={financialfields}
              redirect={redirect}
              loader={loader}
              investmentTotal={investmentTotal}
              locale={locale}
              numberOfUnits={numberOfUnits}
              totalTax={totalTax}
              propertyDetail={propertyDetail}
              propertyContent={propertyContent}
            />
          )}
          {/* {rawdata && (
            <PreviousOrder
              tabs={rawdata.previous_order_section}
              primarytraderequests={primarytraderequests}
              registrationStatus={registrationStatus}
              appraisalSection={rawdata.prop_appraisal_section}
              propertyDetail={propertyDetail}
              locale={locale}
              loader={loader}
            />
          )} */}
        </ScrollableContent>
      </Panel>
    </Col>
  );
};
RightPanel = connect((state, props) => {
  const selector = formValueSelector('issue_status');
  const numberOfUnits = selector(state, 'no_of_units');
  const spendingPrice = selector(state, 'price');
  const investmentTotal = numberOfUnits * spendingPrice;
  const totalTax = props.tradingFee * 0.13;
  const investor = props.investorType && props.investorType;
  const investorName = props.investorName && props.investorName;

  const current = new Date();
  const todaysDate = `${current.getFullYear()}/${current.getMonth() +
    1}/${current.getDate()}`;

  const rawObject =
    props.rawdata && props.rawdata.stage_section
      ? props.rawdata.stage_section.find(
          item => item.stagename === 'order_completed',
        )
      : '';

  const modalData =
    rawObject && rawObject.modaldata
      ? rawObject.modaldata.find(item => item.modalformname === 'issue_status')
      : '';

  const accreditedInvestor = modalData && modalData.investorlabel1;
  const accreditedInvestorContent =
    modalData && modalData.accreditedinvestorcontent;
  const eligibleInvestor = modalData && modalData.investorlabel2;
  const eligibleInvestorContent =
    modalData && modalData.eligibleinvestorcontent;
  const noneligibleInvestor = modalData && modalData.investorlabel3;
  const noneligibleInvestorContent =
    modalData && modalData.noneligibleinvestorcontent;

  const investorProfile =
    investor && investor.includes('ACC')
      ? accreditedInvestor
      : investor && investor.includes('NONE')
      ? noneligibleInvestor
      : investor && investor.includes('ELI')
      ? eligibleInvestor
      : '';

  const investorContent =
    investor && investor.includes('ACC')
      ? accreditedInvestorContent
      : investor && investor.includes('NONE')
      ? noneligibleInvestorContent
      : investor && investor.includes('ELI')
      ? eligibleInvestorContent
      : '';

  // const modalContent =
  //   modalData &&
  //   modalData.content
  //     .replace('replace_1', `${formatters.currency(investmentTotal)}`)
  //     .replace('replace_2', `${formatters.currency(props.tradingFee)}`)
  //     .replace('replace_3', `${formatters.currency(totalTax)}`)
  //     .replace('[INVESTOR]', `${investorProfile}`)
  //     .replace('[INVESTOR_INFO]', `${investorContent}`)
  //     .replace('replace_4', `${formatters.currency(investmentTotal)}`)
  //     .replace('replace_5', `${formatters.currency(investmentTotal)}`)
  //     .replace('[name]', `${investorName}`)
  //     .replace('[ticker]', `${props.symbol}`)
  //     .replace('[quantity]', `${numberOfUnits}`)
  //     .replace('[price]', `${formatters.currency(spendingPrice)}`)
  //     .replace('[trade]', `${formatters.currency(investmentTotal)}`)
  //     .replace('[commission]', `${formatters.currency(props.tradingFee)}`)
  //     .replace('[hst]', `${formatters.currency(totalTax)}`)
  //     .replace('[date]', `${todaysDate}`);

  return {
    // modalContent,
    investmentTotal,
    numberOfUnits,
    totalTax,
  };
})(RightPanel);
export default RightPanel;
