/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Form } from 'react-bootstrap';
import Icon from 'components/Icon';
const pushIn = css`
  transform: scale(0.9);
`;

const StyledIcon = styled(Icon)`
  transition: transform 0.2s ease;
  ${({ clicked }) => clicked && pushIn}
`;
const StyledFormText = styled(({ status, ...rest }) => <Form.Text {...rest} />)`
  ${({ theme }) => css`
    color: ${theme.colors.greys.dark};
  `}
  ${({ status, theme }) =>
    status === 'success' &&
    css`
      color: ${theme.colors.system.success};
    `}
  ${({ status, theme }) =>
    status === 'error' &&
    css`
      color: ${theme.colors.system.danger};
    `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.greys.medium};
    `}
`;

const HelperText = props => (
  <StyledFormText {...props}>{props.children}</StyledFormText>
);

const StyledFormGroup = styled(({ helperMessage, ...rest }) => (
  <Form.Group {...rest} />
))`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    min-height: 59px;
    align-items: center;
    border-radius: ${theme.shapes.borderRadiusSmall};
    background-color: ${theme.colors.greys.light} !important;
    border-color: ${theme.colors.greys.medium};
  `}
  ${({ disabled, theme }) =>
    disabled &&
    css`
      border-color: ${theme.colors.greys.medium};
      background-color: ${theme.colors.greys.light};
      color: ${theme.colors.greys.medium};
    `}
`;
const StyledImage = styled.img`
  -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
  filter: blur(5px);
`;
const StyledDiv = styled.div`
  @keyframes open {
    0% {
      background-position: 166% -25%, 220% -69%;
    }
    100% {
      background-position: 120% -5%, 200% -40%;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .field {
    margin: 5px;
    display: flex;
    flex-direction: column;
  }
  input[type='file'] {
    display: none;
  }

  span {
    text-transform: uppercase;
    font-weight: 700;
    color: #676767;
  }

  input {
    border-radius: 15px;
    border: 1px solid #b7b7b7;
    padding: 5px 5px 5px 10px;
    font-size: 18px;
    transition: 0.2s;
  }
  input:focus {
    outline: none;
    border: 1px solid #64d488;
  }
  input::placeholder {
    color: #bebebe;
  }

  .formbold-mb-5 {
    margin-bottom: 20px;
  }
  .formbold-pt-3 {
    padding-top: 12px;
  }
  .formbold-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
  }

  .formbold-form-wrapper {
    margin: 0 auto;
    max-width: 550px;
    width: 100%;
    background: white;
  }
  .formbold-form-label {
    display: block;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-bottom: 12px;
  }
  .formbold-form-label-2 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .formbold-form-input {
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
  }
  .formbold-form-input:focus {
    border-color: #6a64f1;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold-btn {
    text-align: center;
    font-size: 16px;
    border-radius: 6px;
    padding: 14px 32px;
    border: none;
    font-weight: 600;
    background-color: #6a64f1;
    color: white;
    cursor: pointer;
  }
  .formbold-btn:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
  }

  .formbold--mx-3 {
    margin-left: -12px;
    margin-right: -12px;
  }
  .formbold-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .w-full {
    width: 100%;
  }

  .formbold-file-input input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .formbold-file-input label {
    position: relative;
    border: 1px dashed grey;
    border-radius: 6px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px;
    text-align: center;
  }
  .formbold-drop-file {
    display: block;
    font-weight: 600;
    color: #07074d;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .formbold-or {
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    display: block;
    margin-bottom: 8px;
  }
  .formbold-browse {
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    display: inline-block;
    padding: 8px 28px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
  }

  .formbold-file-list {
    border-radius: 6px;
    background: #f5f7fb;
    padding: 16px 32px;
  }

  .formbold-file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .formbold-file-item button {
    color: #07074d;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .formbold-file-name {
    font-weight: 500;
    font-size: 16px;
    color: #07074d;
    padding-right: 12px;
  }
  .formbold-progress-bar {
    margin-top: 20px;
    position: relative;
    width: 100%;
    height: 6px;
    border-radius: 8px;
    background: #e2e5ef;
  }

  .formbold-progress {
    position: absolute;
    width: 75%;
    height: 100%;
    left: 0;
    top: 0;
    background: #6a64f1;
    border-radius: 8px;
  }

  @media (min-width: 540px) {
    .sm\:w-half {
      width: 50%;
    }
  }

`;

const Wrap = styled.div`
  &:hover:before {
    font-size: 0.5rem;
  }
    width: 150px;
    height: 150px;
    text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
  font-size: 1rem;
        text-align: center;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
`;
const WillowFormFile = props => {
  const { helperMessage, label, status, disabled } = props;
  const [fileName, setFileName] = useState(false);
  const [clicked, setClicked] = useState(false);
  const convertBase64 = (e, name) => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
        reject(error);
      };
    });
    promise.then(va => {
      // props.setImage({ [name]: va });
    });
  };
  const handleIconClick = (e) => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
  };
  return (
    <StyledDiv>
      {fileName && <h4 className='text-center'>File successfully uploaded!</h4>}
      <StyledFormGroup {...props}>
        <div class="mb-6 pt-4">
          <label class="formbold-form-label formbold-form-label-2">
            Upload File
          </label>

          <div class="formbold-mb-5 formbold-file-input">
            <input
              accept=".pdf"
              name={props.name}
              {...props}
              id="file"
              type="file"
              onChange={e => {
                convertBase64(e, props.name);
                setFileName({
                  [props.name]: URL.createObjectURL(e.target.files[0]),
                });
              }}
            />
            <label htmlFor="file" onClick={handleIconClick}>
              <StyledIcon
                variant="download"
                color="#231049"
                clicked={clicked}
                size="xlarge"
              />
            </label>
          </div>
        </div>
      </StyledFormGroup>
      {helperMessage && (
        <HelperText status={status} disabled={disabled}>
          {helperMessage}
        </HelperText>
      )}
    </StyledDiv>
  );
};

export default WillowFormFile;
