/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PrimaryModal from 'components/PrimaryModal';
import RenderField from 'components/RenderField';
import RenderForm from 'components/RenderForm';
import Elements from 'components/Elements';
import Button from 'components/Button';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, formValueSelector, FieldArray, SubmissionError } from 'redux-form';
import Pdfviewer from 'components/PdfLoader/Pdfviewer';
import { required } from 'utils/validation';
import { uid } from '../../utils/ui';
import renderFields from '../RenderFieldArray';
function execFn(fnName, ctx, params) {
  if (params) {
    return ctx[fnName](...params);
  }
  return ctx[fnName];
}
const PageWrapper = ({ hasBackButton, wrapper, children }) =>
  hasBackButton ? wrapper(children) : <div key="1">{children}</div>;

const group = (
  replaceTokens,
  dropDownOptions,
  buttonFunctionArray,
  forms,
  initialValuesOfForm,
  submitFunction,
  context,
  apiErrorMessage,
  content,
  phone,
  verificationcode,
  twoFaemail,
  twoFapassword,
  confirm_transfer,
  user,
  iconClick,
  acceptedValues,
  residentialAddress,
  employmentStatus,
  accreditedInvestorReason,
  netWorth,
  notTaxResiOfCanada,
  reasonForNoTINRequired,
  dateOfBirth,
  currentStep,
  pdf,
  onScroll,
  listInnerRef,
  disableButton,
  transferFundsLoader,
  loader,
  investorType,
  investorTypeKey,
  submit,
  locale,
  key,
  authFunc,
  errorlookup,
  areYouDeemedInsider,
  areYouInControlPosition,
  areYouPermittedInvestor,
  areYouTaxResidentOfOtherThanCanada,
  areYouPEP,
  areYouThirdParty,
  explicitForm
) => {
  const fieldArray = fields =>
    fields.map((formRow, index) => (
      <Row key={`form-row-${index}`} className={formRow.class}>
        {formRow.fields &&
          formRow.fields.map((item, index2) => (
            <Col
              className={`${
                item.type === 'pdf' ? 'd-flex justify-content-center' : ''
              } mb-2`}
              key={index2}
              sm={12}
              md={12}
            >
              {item.type === 'netWorth' &&
                item.paragraphs.map(para => (
                  <Elements
                    key={para.id}
                    tag={para.tag}
                    className={para.class}
                    iconClick={() => iconClick(para.info_icon_blurb)}
                  >
                    {para.content.includes('[Net Worth]')
                      ? `${para.content.replace(
                        '[Net Worth]',
                          '',
                      )} : $${netWorth.toLocaleString('en-US')}`
                      : replaceTokens
                        ? replaceTokens(para.content, false)
                      : para.content}
                  </Elements>
                ))}
              {item.type !== 'netWorth' &&
                item.paragraphs &&
                item.paragraphs.map(para => (
                  <Elements key={para.id} tag={para.tag} className={para.class}>
                    {para.content}
                  </Elements>
                ))}
              {item.type === 'pdf' ? (
                <Pdfviewer pdf={pdf} />
              ) : (
                <>
                  {item.type !== 'netWorth' && (
                    <Field
                      name={item.name}
                      type={item.type}
                      label={
                        item.replaceLabelwithFieldValue
                          ? replaceTokens(
                            item.replaceLabelwithFieldValue,
                              confirm_transfer,
                          )
                          : item.label
                      }
                      initialradiovalue={acceptedValues[item.name]}
                      radiovalue={item.radiovalue}
                      radiodescription={item.radiotext}
                      stickyph={item.stickyph}
                      fieldid={`row${index}-col${index2}-${item.name}`}
                      className={item.class}
                      autofillcolor={item.autofillcolor}
                      component={RenderField}
                      disabled={item.disabled}
                      options={
                        item.options
                          ? dropDownOptions(item) !== false
                            ? dropDownOptions(item)
                            : item.options
                          : []
                      }
                      validate={
                        item.validate &&
                        (item.validate === 'ignorevalidation'
                          ? reasonForNoTINRequired
                            ? [required]
                            : null
                          : [execFn(item.validate, context), required])
                      }
                      max={item.type === 'date' ? '9999-12-31' : ''}
                      decimalscale={item.decimalscale}
                    />
                  )}
                </>
              )}

              <>
                {item.hasconditionalfields &&
                  item.flags.length > 0 &&
                  item.flags.includes(notTaxResiOfCanada) &&
                  fieldArray(item.subfields)}
                {item.hasconditionalfields &&
                  typeof accreditedInvestorReason !== 'undefined' &&
                  item.flags.includes(accreditedInvestorReason) &&
                  fieldArray(item.subfields)}
                {item.hasconditionalfields &&
                  item.flags.length > 0 &&
                  item.flags.includes(employmentStatus) &&
                  fieldArray(
                    item.subfields.filter(
                      field =>
                        field.group_names &&
                        field.group_names.includes(employmentStatus),
                    ),
                  )}
                {item.hasconditionalfields &&
                  typeof areYouDeemedInsider !== 'undefined' &&
                  item.flags.includes(areYouDeemedInsider) &&
                  fieldArray(item.subfields)}
                {item.hasconditionalfields &&
                  typeof areYouInControlPosition !== 'undefined' &&
                  item.flags.includes(areYouInControlPosition) &&
                  fieldArray(item.subfields)}
                {item.hasconditionalfields &&
                  typeof areYouPermittedInvestor !== 'undefined' &&
                  item.flags.includes(areYouPermittedInvestor) &&
                  fieldArray(item.subfields)}
                {item.hasconditionalfields &&
                  typeof areYouTaxResidentOfOtherThanCanada !== 'undefined' &&
                  item.flags.includes(areYouTaxResidentOfOtherThanCanada) &&
                  fieldArray(item.subfields)}
                {item.hasconditionalfields &&
                typeof areYouPEP !== 'undefined' &&
                item.flags.includes(areYouPEP) &&
                fieldArray(item.subfields)}
                {item.hasconditionalfields &&
                typeof areYouThirdParty !== 'undefined' &&
                item.flags.includes(areYouThirdParty) &&
                fieldArray(item.subfields)}
              </>
            </Col>
          ))}
      </Row>
    ));
  return (
    <div key={key}>
      {forms &&
        forms.map((form, formkey) => (
          <div key={`formdiv${formkey}`}>
            <RenderForm
              className={form.class}
              key={`form${formkey}`}
              destroyonunmount={form.destroyonunmount}
              form={explicitForm ? explicitForm : form.formname}
              centered={form.centered}
              submitFunction={e => {
                let allValues = {};
                if (form.formname === 'personal' || form.formname === 'authorities') {
                  const {
                    address,
                    unitNumber,
                    city,
                    province,
                    country,
                    postalCode,
                    ...other
                  } = acceptedValues;
                  allValues =
                    Object.keys(residentialAddress).length !== 0
                      ? { residentialAddress, ...other }
                      : { ...other };
                } else {
                  allValues = acceptedValues;
                }
                // if (form.formname === 'directors') {
                //   const validatePercentageOwned = (directors) => {
                //     const totalPercentage = directors.reduce((total, director) => {
                //         return total + parseFloat(director.percentage);
                //     }, 0);
                //     return totalPercentage === 100;
                //   };
                //   if (!validatePercentageOwned(acceptedValues.directors)) {
                //     throw new SubmissionError({ percentage: 'Needs to be 100%', _error: 'Percentage Owned needs to be 100%' })
                //   }
                // }
                if (investorType) {
                  allValues.investorType = investorType;
                  allValues.tags = JSON.stringify({
                    investorTypeKey,
                  });
                }
                if (dateOfBirth === null) {
                  allValues.dateOfBirth = dateOfBirth;
                }
                if (typeof e.amount !== 'undefined') {
                  e.amount = allValues.amount;
                }
                submitFunction({
                  formname: form.formname,
                  data: submit === 'event' ? e : allValues,
                });
              }}
              initializeValue={{
                ...(form.initialvalues &&
                  Object.assign(
                    {},
                    ...form.initialvalues.map(acc => ({
                      [acc.key]: acc.value,
                    })),
                  )),
                ...initialValuesOfForm,
              }}
              apiErrorMessage={apiErrorMessage}
              errorlookup={errorlookup}
              message={
                // TODO: verify if this form.input_error_message ocuurs under which page
                form.input_error_message
                  ? form.input_error_message
                  : form.errormessage
              }
              stacked // TODO: What does this do ?
              buttonArray={form.fieldsArray ? [] : form.buttons}
              buttonLayout={form.buttonlayout && form.buttonlayout}
              transferFundsLoader={transferFundsLoader}
              disableButton={disableButton}
              buttonFunction={e => {
                const targetId = e.target.id.toLowerCase();
                const {
                  address,
                  unitNumber,
                  city,
                  province,
                  country,
                  postalCode,
                  ...other
                } = acceptedValues;

                const allValues =
                  Object.keys(residentialAddress).length !== 0
                    ? { residentialAddress, ...other }
                    : { ...other };

                if (dateOfBirth === null) {
                  allValues.dateOfBirth = dateOfBirth;
                }
                if (form.buttonlayout) {
                  // button id needs to be unique
                  let selectedBtn = false;
                  form.buttonlayout.map(lay =>
                    lay.row.map(rw =>
                      rw.columns.map(cl => {
                        if (
                          typeof cl.buttons.find(
                            item => item.id === e.target.id,
                          ) !== 'undefined'
                        ) {
                          selectedBtn = cl.buttons.find(
                            item => item.id === e.target.id,
                          );
                        }
                      }),
                    ),
                  );

                  execFn(selectedBtn.value, context, [
                    phone,
                    verificationcode,
                    twoFaemail,
                    twoFapassword,
                    allValues,
                    currentStep,
                    targetId,
                    ...buttonFunctionArray,
                    { authFunc: { ...authFunc } },
                    { form: allValues },
                  ]);
                }
                if (form.buttons) {
                  form.buttons.map(item =>
                    execFn(item.value, context, [
                      ...buttonFunctionArray,
                      phone,
                      verificationcode,
                      twoFaemail,
                      twoFapassword,
                      allValues,
                    ]),
                  );
                }
              }}
            >
              <Col
                onScroll={onScroll}
                ref={listInnerRef}
                className={form.formcolclass}
              >
                {form.section.map(sect => (
                  <div className={sect.class} key={sect.id}>
                    {sect.hr && <hr className="mb-6 mt-6" />}
                    {sect.paragraphs &&
                      sect.paragraphs.map(para => (
                        <Elements
                          key={para.id}
                          tag={para.tag}
                          className={para.class}
                          iconClick={() => iconClick(sect.info_icon_blurb)}
                        >
                          {para.content.includes('[Net Worth]')
                            ? `${para.content.replace(
                                '[Net Worth]',
                                '',
                              )} : $${netWorth.toLocaleString('en-US')}`
                            : replaceTokens
                            ? replaceTokens(para.content, false)
                            : para.content}
                        </Elements>
                      ))}
                    {sect.header && <h2 className="h3 mb-6">{sect.header}</h2>}
                    {sect.blurb && (
                      <p className="mb-3">
                        {replaceTokens
                          ? replaceTokens(sect.blurb, false)
                          : sect.blurb}
                      </p>
                    )}
                    {sect.second_blurb && (
                      <p
                        className="mb-3"
                        dangerouslySetInnerHTML={{ __html: sect.second_blurb }}
                      />
                    )}
                    {transferFundsLoader && (
                      <div className="mb-5 text-dark font-bold">
                        {
                          errorlookup.find(
                            error => error.code === transferFundsLoader,
                          ).message
                        }
                        <br />
                        <div className="dot-pulse ms-5 mt-5"></div>
                      </div>
                    )}
                    {loader && (
                      <Row>
                        <Col xs={12} style={{ position: 'relative' }}>
                          <div // Block the UI from any action
                            style={{
                              position: 'fixed',
                              left: 0,
                              right: 0,
                              top: 0,
                              bottom: 0,
                              zIndex: 5000,
                            }}
                          ></div>
                          <div
                            className="d-flex align-items-center"
                            style={{
                              position: 'absolute',
                              left: '50%',
                              top: 25,
                              transform: 'translate(-50%, -50%)',
                            }}
                          >
                            <div
                              className="spinner-border text-warning"
                              role="status"
                              style={{ color: "#D7B02F"}}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {content &&
                      content.map(
                        item =>
                          item.name === form.formname && (
                            <div className="pb-4" key={uid()}>
                              {item.data}
                            </div>
                          ),
                      )}
                      {sect.fieldsArray && <FieldArray name={`${form.formcolclass}`} component={renderFields} layout={sect.layout} buttons={form.buttons} /> }
                      { sect.layout && sect.fieldsArray === undefined && fieldArray(sect.layout)}
                  </div>
                ))}
              </Col>
            </RenderForm>
            {form.paragraphs &&
              form.paragraphs.map(para => (
                <Elements
                  key={para.id}
                  tag={para.tag}
                  className={para.class}
                  dangerouslySetInnerHTML={{
                    __html: replaceTokens
                      ? replaceTokens(para.content, false)
                      : para.content,
                  }}
                ></Elements>
              ))}
          </div>
        ))}
    </div>
  );
};
let Layout = ({
  forms,
  initialValuesOfForm,
  submitFunction,
  context,
  apiErrorMessage,
  modals,
  content,
  buttonFunctionArray,
  phone,
  verificationcode,
  twoFaemail,
  twoFapassword,
  dropDownOptions,
  replaceTokens,
  confirm_transfer,
  iconClick,
  acceptedValues,
  residentialAddress,
  employmentStatus,
  accreditedInvestorReason,
  netWorth,
  notTaxResiOfCanada,
  reasonForNoTINRequired,
  dateOfBirth,
  currentStep,
  pdf,
  hasBackButton,
  backButtonProperties,
  loader,
  investorType,
  investorTypeKey,
  submit,
  locale,
  key,
  authFunc,
  errorlookup,
  transferFundsLoader,
  areYouDeemedInsider,
  areYouInControlPosition,
  areYouPermittedInvestor,
  areYouTaxResidentOfOtherThanCanada,
  areYouPEP,
  areYouThirdParty,
  explicitForm
}) => {
  const [disableButton, setButtonState] = useState(false);
  const listInnerRef = useRef();

  useEffect(() => {
    setButtonState(false);
  }, [currentStep]);

  const onScroll = () => {
    if (currentStep === 'agreements') {
      if (listInnerRef.current) {
        // always keeping the button green under kyc forms
        setButtonState(false);
      }
    }
  };

  return (
    <div key={key}>
      <PageWrapper
        key={`pagewrapper${key}`}
        hasBackButton={hasBackButton}
        wrapper={children =>
          hasBackButton && (
            <Row {...backButtonProperties}>
              <Button
                key="wrapperbutton"
                className="mt-6 mb-6"
                name="decrement"
                type="button"
                iconOnly
                icon="chevronLeft"
                onClick={() =>
                  backButtonProperties.dispatch(
                    execFn(
                      backButtonProperties.functionname,
                      backButtonProperties.context,
                      backButtonProperties.funcparams,
                    ),
                  )
                }
                variant="basic"
                aria-label="Back Button"
              />
              {children}
            </Row>
          )
        }
      >
        {forms &&
          group(
            replaceTokens,
            dropDownOptions,
            buttonFunctionArray,
            forms,
            initialValuesOfForm,
            submitFunction,
            context,
            apiErrorMessage,
            content,
            phone,
            verificationcode,
            twoFaemail,
            twoFapassword,
            confirm_transfer,
            context.user,
            iconClick,
            acceptedValues,
            residentialAddress,
            employmentStatus,
            accreditedInvestorReason,
            netWorth,
            notTaxResiOfCanada,
            reasonForNoTINRequired,
            dateOfBirth,
            currentStep,
            pdf,
            onScroll,
            listInnerRef,
            disableButton,
            transferFundsLoader,
            loader,
            investorType,
            investorTypeKey,
            submit,
            locale,
            key,
            authFunc,
            errorlookup,
            areYouDeemedInsider,
            areYouInControlPosition,
            areYouPermittedInvestor,
            areYouTaxResidentOfOtherThanCanada,
            areYouPEP,
            areYouThirdParty,
            explicitForm
          )}

        {modals && (
          <>
            {modals.map(modal => (
              <PrimaryModal
                {...modal.modalProperties}
                body={
                  <>
                    {group(
                      modal.replaceTokens,
                      modal.dropDownOptions,
                      modal.buttonFunctionArray,
                      modal.forms,
                      modal.initialValuesOfForm,
                      modal.submitFunction,
                      modal.context,
                      modal.apiErrorMessage,
                      modal.content,
                      phone,
                      verificationcode,
                      twoFaemail,
                      twoFapassword,
                      confirm_transfer,
                      modal.context.user,
                      iconClick,
                      acceptedValues,
                      residentialAddress,
                      employmentStatus,
                      accreditedInvestorReason,
                      netWorth,
                      notTaxResiOfCanada,
                      reasonForNoTINRequired,
                      dateOfBirth,
                      currentStep,
                      pdf,
                      onScroll,
                      listInnerRef,
                      disableButton,
                      transferFundsLoader,
                      loader,
                      investorType,
                      investorTypeKey,
                      submit,
                      locale,
                      key,
                      authFunc,
                      errorlookup,
                      areYouDeemedInsider,
                      areYouInControlPosition,
                      areYouPermittedInvestor,
                      areYouTaxResidentOfOtherThanCanada,
                      areYouPEP,
                      areYouThirdParty,
                      explicitForm
                    )}
                  </>
                }
              />
            ))}
          </>
        )}
      </PageWrapper>
    </div>
  );
};
Layout = connect((state, props) => {
  const { form } = state;
  const acceptedValues = {};
  const twoFAFormselector = formValueSelector('two_step_verification');
  const applyVerificationCodeFormSelector = formValueSelector(
    'applyVerificationCode',
  );
  const refreshCredentialFormSelector = formValueSelector('credentials');
  const transferFundsFormSelector = formValueSelector('transfer_funds');
  const missingAccountFormSelector = formValueSelector('missing_accounts');
  const tmpOrderBoxForm = formValueSelector('tmpOrderBoxForm');

  const phone = twoFAFormselector(state, 'phoneNumber');

  const verificationcode = applyVerificationCodeFormSelector(
    state,
    'verificationcode',
  );
  const twoFaemail = refreshCredentialFormSelector(state, 'email');
  const twoFapassword = refreshCredentialFormSelector(state, 'password');
  const tfAmount =
    transferFundsFormSelector(state, 'amount') ||
    missingAccountFormSelector(state, 'amount') || 
    tmpOrderBoxForm(state, 'amount');
  const tftransferDirection = transferFundsFormSelector(
    state,
    'transfer_direction',
  );
  if (tfAmount) {
    acceptedValues.amount = parseFloat(
      tfAmount.toString().replace(/\$|,/g, ''),
    );
  }
  // kycFormDetails
  const formData = Object.keys(form).length > 0 && form[props.currentStep];
  Object.assign(acceptedValues, props.currentStep.values);
  const registeredFieldsName =
    formData &&
    formData.registeredFields &&
    Object.keys(formData.registeredFields);
  const kycselector = formValueSelector(props.currentStep);
  const liabilities = kycselector(state, 'liabilities');
  const fixedAssets = kycselector(state, 'fixedAssets');
  const liquidAssets = kycselector(state, 'liquidAssets');
  const annualIncome = kycselector(state, 'annualIncome');
  
  // eslint-disable-next-line no-unused-expressions
  registeredFieldsName &&
    registeredFieldsName.length > 0 &&
    registeredFieldsName.forEach(
      // eslint-disable-next-line no-return-assign
      field => (acceptedValues[field] = kycselector(state, field)),
    );
  // Removing commas before submitting because database doesn't store number with commas
  if (liabilities || fixedAssets || liquidAssets || annualIncome) {
    acceptedValues.liabilities = parseInt(
      liabilities.toString().replace(/\$|,/g, ''),
    );
    acceptedValues.fixedAssets = parseInt(
      fixedAssets.toString().replace(/\$|,/g, ''),
    );
    acceptedValues.liquidAssets = parseInt(
      liquidAssets.toString().replace(/\$|,/g, ''),
    );
    acceptedValues.annualIncome = parseInt(
      annualIncome.toString().replace(/\$|,/g, ''),
    );
  }
  
  const dateOfBirthValue = kycselector(state, 'dateOfBirth');
  let dateOfBirth = false;
  if (dateOfBirthValue === 'yyyy-MM-dd') {
    dateOfBirth = null;
  }
  const notTaxResiOfCanada = kycselector(state, 'notTaxResiOfCanada');
  const accreditedInvestorReason = kycselector(
    state,
    'accreditedInvestorReason',
  );
  const areYouDeemedInsider = kycselector(state, 'areYouDeemedInsider');
  const areYouInControlPosition = kycselector(state, 'areYouInControlPosition');
  const areYouPermittedInvestor = kycselector(state, 'areYouPermittedInvestor');
  const areYouTaxResidentOfOtherThanCanada = kycselector(state, 'areYouTaxResidentOfOtherThanCanada');
  const areYouPEP = kycselector(state, 'areYouPEP');
  const areYouThirdParty = kycselector(state, 'areYouThirdParty');

  const eligibleInvestorReason = kycselector(state, 'eligibleInvestorReason');
  const employmentStatus = kycselector(state, 'employmentStatus');
  const taxIdNumber = kycselector(state, 'taxIdNumber');
  const reasonForNoTIN = kycselector(state, 'reasonForNoTIN');
  let reasonForNoTINRequired = false;
  if (props.currentStep === 'financial') {
    reasonForNoTINRequired = true;
    if (reasonForNoTIN && taxIdNumber) {
      reasonForNoTINRequired = false;
    }
    if (reasonForNoTIN || taxIdNumber) {
      reasonForNoTINRequired = false;
    }
  }
  let investorType = 'none';
  let investorTypeKey = 'none';
  if (props.wpFinContent) {
    Object.keys(props.wpFinContent).map(wpfield => {
      // accreditedInvestor1 InvestorType = none
      // none InvestorType = none
      // accreditedInvestor(N) InvestorType = acc
      // eligibleInvestor(N) but accreditedInvestor(N) InvestorType = acc
      // eligibleInvestor(N) InvestorType = elible

      if (accreditedInvestorReason) {
        if (
          props.wpFinContent[wpfield] === accreditedInvestorReason ||
          (props.wpFinContent[wpfield] === eligibleInvestorReason &&
            wpfield !== 'none')
        ) {
          if (
            wpfield !== 'accreditedInvestor1' &&
            wpfield.includes('accredited')
          ) {
            investorType = 'accredited';
            investorTypeKey = wpfield;
          }
          if (
            eligibleInvestorReason && // If this was set in past
            accreditedInvestorReason && // if this is from 2-4 index
            props.wpFinContent.accreditedInvestor1.includes(
              accreditedInvestorReason,
              0,
            ) === false // Should not be equal to No, none of the above
          ) {
            investorType = 'accredited';
            investorTypeKey = wpfield;
          }
          if (
            eligibleInvestorReason &&
            accreditedInvestorReason &&
            props.wpFinContent.accreditedInvestor1.includes(
              accreditedInvestorReason,
              0,
            ) // Should be equal to No, none of the above
          ) {
            if (wpfield.includes('eligible')) {
              investorType = 'eligible';
              investorTypeKey = wpfield;
            }
          }
        }
      }
    });
  }
  // eslint-disable-next-line no-restricted-globals
  const netWorth = isNaN(
    parseInt(acceptedValues.liquidAssets) +
      parseInt(acceptedValues.fixedAssets) -
      parseInt(acceptedValues.liabilities),
  )
    ? 0
    : parseInt(acceptedValues.liquidAssets) +
      parseInt(acceptedValues.fixedAssets) -
      parseInt(acceptedValues.liabilities);

  let residentialAddress = false;

  if (props.currentStep === 'personal' || props.currentStep === 'tradeAuthority') {
    residentialAddress = kycselector(
      state,
      'address',
      'unitNumber',
      'city',
      'province',
      'country',
      'postalCode',
    );
  }
  // TODO: verify if this is being used as a context ?
  const getAccount = id =>
    state.users.data.current &&
    state.users.data.current.account.bankAccounts
      .filter(a => a.id == id)
      .map(a => e.displayName);
  const confirm_transfer = [
    {
      pattern: '[en/direction]',
      replaceWith: tftransferDirection
        ? tftransferDirection.includes('Transfer to Midasboard')
          ? 'from my bank account to my Midasboard account'
          : 'from my Midasboard account to my bank account'
        : '',
    },
    {
      pattern: '[fr/direction]',
      replaceWith: tftransferDirection
        ? tftransferDirection.includes('Transfert vers Midasboard')
          ? 'de mon compte bancaire à mon compte Midasboard'
          : 'de mon compte Midasboard à mon compte bancaire'
        : '',
    },
    {
      pattern: '[amount]',
      replaceWith: `$${tfAmount}`,
    },
  ];
  return {
    phone,
    verificationcode,
    twoFaemail,
    twoFapassword,
    confirm_transfer,
    acceptedValues,
    residentialAddress,
    employmentStatus,
    accreditedInvestorReason,
    netWorth,
    notTaxResiOfCanada,
    reasonForNoTINRequired,
    dateOfBirth,
    investorType,
    investorTypeKey,
    areYouDeemedInsider,
    areYouInControlPosition,
    areYouPermittedInvestor,
    areYouTaxResidentOfOtherThanCanada,
    areYouPEP,
    areYouThirdParty
    // apierrormsg,
  };
})(Layout);

export default Layout;
