import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  makeSelectLocale,
  makeSelectEnvVariablesData,
  makeSelectApi
} from 'containers/App/selectors';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import { API_ENPOINT_PREFIX } from 'containers/App/constants';
import { wpurl, isServer } from 'utils/detection';
import request from 'utils/request';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import {
  loadPortfolioPageDataSuccess,
  loadPortfolioPageDataFailure,
  loadPortfolioPageData,
  setPurchases,
} from './actions';
import { USER_LOADED } from '../App/Users/constants';
import { LOAD_PORTFOLIO_PAGE_DATA_SUCCESS } from './constants';

// function* setPortfolioPage(API_URL) {
//   const API_URL_PORTFOLIO_PAGE = `${API_URL}/getportfoliocontent`;
//   const locale = yield select(makeSelectLocale());
//   const requestUrl = `${API_URL_PORTFOLIO_PAGE}/${locale}`;
//   try {
//     yield put(loadPortfolioPageData());
//     const someData = yield call(request, requestUrl);
//     yield put(loadPortfolioPageDataSuccess(someData));
//   } catch (e) {
//     yield put(loadPortfolioPageDataFailure());
//   }
// }
function* setIssues() {
  const api = yield select(makeSelectApi());
  try {
    const issuer = yield api.get('api/issuer');
    const issuerId = issuer.id;
      const result = yield api.get('api/issues', { params: { text: `issuerId%${issuerId}` } }); //all issues associate to portal 
    
  } catch (err) {
    console.error('Error fetching issues', err);
    // yield put(loadDefaultPageDataFailure());
  }

}


function* getPurchases() {
  const user = yield select(makeSelectUsers());
  if (user.initialized && user.current.isAuthenticated) {
    const api = yield select(makeSelectApi());
    // const sorting = [
    //   {
    //     transactionId: {
    //       order: 'asc', // or 'desc' for descending order
    //     },
    //   },
    // ];
    // const url = encodeURIComponent(JSON.stringify(sorting));
    try {
        const result = yield api.get(`api/users/purchases`);
        yield put(setPurchases(result));
    } catch (err) {
      console.error('Error fetching issues', err);
    }
  }
}

export default function* portfolioSaga() {
    // yield call(setIssues);
    // yield call(getPurchases);
  
  // const { variables } = yield select(makeSelectEnvVariablesData());
  // const API_URL = `http://willow.local/${API_ENPOINT_PREFIX}`; // wordpressURL
  // yield call(setPortfolioPage, API_URL);
  const users = yield select(makeSelectUsers());
  const auth = yield select(makeSelectAuth());
  if (users.initialized) {
    const locale = yield select(makeSelectLocale());
    // it works because initially isAuthenticated is false so it will show a flash of login page on refresh
    if (!users.current.isAuthenticated && !auth.isAuthenticated) {
      yield put(push(`/${locale}/login`));
    }
  }
  yield takeLatest(
    [USER_LOADED, LOCATION_CHANGE, CHANGE_LOCALE, LOAD_PORTFOLIO_PAGE_DATA_SUCCESS],
    getPurchases,
  );
  yield takeLatest(
    [USER_LOADED, LOCATION_CHANGE, CHANGE_LOCALE],
    setIssues,
  );
}
