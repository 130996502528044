/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable guard-for-in */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API_ENPOINT_PREFIX } from 'containers/App/constants';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';
import { wpurl } from 'utils/detection';
import { getKycStatus } from 'utils/kyc';
import firebase from 'firebase/app';
import 'firebase/auth'; // for authentication
import {
  makeSelectEnvVariablesData,
  makeSelectApi,
  makeSelectEvents,
} from 'containers/App/selectors';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SIGN_OUT_SUCCESS } from 'containers/AuthProvider/constants';
import { USER_LOADED } from 'containers/App/Users/constants';
import { SERVER_EVENT } from 'containers/App/Events/constants';
import {
  loadReviewDataSuccess,
  loadReviewDataFailure,
  toggleStage,
  loadRegistration,
  setOrderBoxLoader,
  loadIssuesData,
  loadIssueData,
  loadUserDocuments,
} from './actions';
import {
  SUBMIT_REVIEW_ORDER,
  SUBMIT_FORM_DATA,
  SUBMIT_WATCHLIST,
  REMOVE_WATCHLIST,
  TOGGLE_DOWNLOAD,
  CLICKED_DOCUMENT,
  LOAD_ISSUE_DATA,
} from './constants';
import { makeSelectTradeViewPage } from './selectors';
import { authChanged, signOutSuccess } from '../AuthProvider/actions';
import { setToast } from 'containers/AuthPage/actions';
// function* getTradeviewContent(API_URL) {
//   // const API_URL_TRADEVIEW_TEMPLATE = `${API_URL}/gettradeviewcontent`;

//   const locale = yield select(makeSelectLocale());
//   yield put(loadTradeviewContent());
//   yield put(toggleMainTab(false));
//   const requestUrl = `${API_URL_TRADEVIEW_TEMPLATE}/${locale}`;
//   try {
//     const someData = yield call(request, requestUrl);
//     yield put(loadTradeviewContentSuccess(someData));
//   } catch (e) {
//     yield put(loadTradeviewContentFailure());
//   }
// }
// function* getSinglePropertyContent(API_URL) {
//   const API_URL_SINGLE_PROPERTY = `${API_URL}/getsingleproperty`;

//   const slug = yield select(makeSelectLocation());
//   const locale = yield select(makeSelectLocale());
//   const postname = slug.pathname.split('/')[3];
//   const requestUrl = `${API_URL_SINGLE_PROPERTY}/${locale}/${postname}`;
//   yield put(loadSinglePropertyContent());
//   try {
//     const someData = yield call(request, requestUrl);
//     yield put(loadSinglePropertyContentSuccess(someData));
//   } catch (e) {
//     yield put(loadSinglePropertyContentFailure());
//   }
// }

// function* getKycWpContent(API_URL) {
//   const API_URL_SINGLE_PROPERTY = `${API_URL}/getkycfinancial`;

//   const locale = yield select(makeSelectLocale());
//   const requestUrl = `${API_URL_SINGLE_PROPERTY}/${locale}`;
//   yield put(loadKYCWPContent());
//   try {
//     const someData = yield call(request, requestUrl);
//     yield put(loadKYCWPContentSuccess(someData));
//   } catch (e) {
//     yield put(loadKYCWPContentFailure());
//   }
// }

function* addWatchlist({ symbol }) {
  try {
    const api = yield select(makeSelectApi());
    yield api.post('properties/watchlist', {
      ids: [symbol],
    });
  } catch (error) {
    // NOTE: fail silently
    console.log(error);
  }
}

function* removeWatchlist({ symbol }) {
  try {
    const api = yield select(makeSelectApi());
    yield api.delete('properties/watchlist', {
      ids: [symbol],
    });
  } catch (error) {
    // NOTE: fail silently
    console.log(error);
  }
}

function* handleReviewOrder({ formdata, symbol }) {
  try {
    const pageData = yield select(makeSelectTradeViewPage());
    const { wpcontent } = pageData;
    const issueId = wpcontent.issue.issueId;
    const numericString = parseFloat(formdata.formvalues.investment_amount.replace(/[$,]/g, ''));
    const api = yield select(makeSelectApi());
    const response = yield api.post('order-sequencer/purchase-request/prepare', {
      // "issueSymbol": symbol,
      "issueId": issueId,
      // "issueId": "042a8539-324f-45b2-9cd1-0278cf2d349f",
      // returnURL,
      amount: parseFloat(numericString),
      currency: formdata.currency
      // secondaryUserId: null,
    });
    yield put(loadReviewDataSuccess({...response, amount: formdata.formvalues.investment_amount, currency: formdata.currency}));
    yield put(toggleStage('review_order'));
  } catch (error) {
    console.log('error: ', error);
    // const pageData = yield select(makeSelectTradeViewPage());
    // const { wpcontent } = pageData;
    // const pageContent = wpcontent.pageContent.content;
    // const rightPanelContent =
    //   pageContent &&
    //   pageContent
    //     .filter(item => typeof item.right_panel !== 'undefined')
    //     .map(item => item.right_panel);
    // const { current } = yield select(makeSelectUsers());
    // const userAuthStatus =
    //   current.isAuthenticated === true ? 'authenticated' : 'unauthenticated';
    // const rightPanelContentBasedOnStatus =
    //   rightPanelContent &&
    //   rightPanelContent.find(
    //     item =>
    //       item.status === userAuthStatus &&
    //       item.view ===
    //         getKycStatus(
    //           current.registration.status,
    //           current.account.bankTransactions,
    //           current.account.availableFunds,
    //         )
    //   );
      if (Array.isArray(error)) {
        yield put(
          loadReviewDataFailure(error[0].message
            // rightPanelContentBasedOnStatus.errorlookup.find(o1 =>
            //   error.some(o2 => o1.code === o2.code),
            // ).message,
          ),
        );
      } 
      else {
        yield put(
          loadReviewDataFailure("Something went wrong")
        );
      }
  }
}

function* loadRegistrationdata() {
  const user = yield select(makeSelectUsers());
  if (user.current.isAuthenticated) {
    try {
      const api = yield select(makeSelectApi());
      const result = yield api.get('kyc/registration/current');
      // TODO: Add any request modifications here
      if (result.personal && result.personal.dependants !== null) {
        result.personal.dependants = result.personal.dependants.toString();
      }
      if (result.employment && result.employment.areYouPEPorHIO !== null) {
        result.employment.areYouPEPorHIO = result.employment.areYouPEPorHIO.toString();
      }
      yield put(loadRegistration(result));
      return result;
    } catch (error) {
      if(error.response.status === 401) {
        firebase.auth().signOut();
        yield put(authChanged(false, false));
        yield put(signOutSuccess());
        yield put(setToast('Make sure you are on the correct subdomain.'));
      } else {
        yield put(setToast('Server unable to respond, try again later!!'));
      }
    }
  }
}

function* handleSubmit({ content, units, symbol }) {
  const pageData = yield select(makeSelectTradeViewPage());
  const { wpcontent } = pageData;
  const pageContent = wpcontent.pageContent.content;
  const rightPanelContent =
    pageContent &&
    pageContent
      .filter(item => typeof item.right_panel !== 'undefined')
      .map(item => item.right_panel);
  const { current } = yield select(makeSelectUsers());
  const userAuthStatus =
    current.isAuthenticated === true ? 'authenticated' : 'unauthenticated';
  const rightPanelContentBasedOnStatus =
    rightPanelContent &&
    rightPanelContent.find(
      item =>
        item.status === userAuthStatus &&
        item.view ===
          getKycStatus(
            current.registration.status,
            current.account.bankTransactions,
            current.account.availableFunds,
          )
    );
  // yield put(toggleStage('order_completed'));
  yield put(setOrderBoxLoader(true));
  // const { kycData } = yield select(makeSelectTradeViewPage());
  // const { tags } = kycData.registration && kycData.registration.financial;
  // const selectedFieldName = JSON.parse(tags).investorTypeKey;
  // const ipoLabel = kycData.data[selectedFieldName];

  // let docContent = content;
  // docContent = docContent.concat(
  //   `<h4>You have Chosen: </h4></br><p>${ipoLabel}</p>`, // TODO: needs translation
  // );
  try {
    const api = yield select(makeSelectApi());
    const { host } = window.location;
    const returnURL = `http://${host}/en/home`;
    const { wpcontent, reviewData} = yield select(makeSelectTradeViewPage());
    const issueId = wpcontent.issue.issueId;
    const numericString = parseFloat(reviewData.data.amount.replace(/[$,]/g, ''));
    const response = yield api.post('order-sequencer/purchase-request/create', {
      "issueSymbol": symbol,
      "issueId": issueId,
      returnURL,
      amount: parseFloat(numericString),
      currency: reviewData.data.currency,
      secondaryUserId: null,
    });
    yield put(loadReviewDataSuccess(response));
    yield put(setOrderBoxLoader(false));
    if (response.status === 204) {
      yield put(
        loadReviewDataFailure(
          rightPanelContentBasedOnStatus.errorlookup.find(
            o2 => o2.code === 'success',
          ).message,
        ),
      );
    }
  } catch (error) {
    yield put(setOrderBoxLoader(false));
    if (error.find(err => err.code === "Docusign Failure")) {
      yield put(toggleStage('error_screen'));
    } else if (Array.isArray(error)) {
      yield put(
        loadReviewDataFailure(
          rightPanelContentBasedOnStatus.errorlookup.find(o1 =>
            error.some(o2 => o1.code === o2.code),
          ).message,
        ),
      );
    } else {
      yield put(
        loadReviewDataFailure("Something went wrong")
      );
    }
  }
}
function* resetReviewData() {
  const { event } = yield select(makeSelectEvents());
  if (typeof event !== 'undefined' && event.name.includes('Order Placed')) {
    yield put(setOrderBoxLoader(false));
  }
}
function* unassignUser() {
  yield put(loadRegistration(false));
}

function* changeStage() {
  yield put(toggleStage('issue_status'));
}

function* setIssuesData() {
  const api = yield select(makeSelectApi());
  try {
    const { host } = window.location;
      const subdomain = host.split('.')[0];
      let result = null;
      if (subdomain === 'portal') {
        result = yield api.get('api/issues');
        yield put(loadIssuesData(result));
      } else {
        const issuer = yield api.get('api/issuer');
        const issuerId = issuer.id;
        result = yield api.get('api/issues', {params: {text: `issuerId%${issuerId}`}});
        yield put(loadIssuesData(result));
      }
    
     const parts = window.location.href.split('/');
     const symbol = parts[parts.length - 1];
      yield put(loadIssueData(result.results.find(res => res.symbol === symbol)))
  } catch (err) {
    console.error('Error fetching issues', err);
  }
}

function* getUserDocuments() {
  const { wpcontent } = yield select(makeSelectTradeViewPage());
  const issue = wpcontent.issue;
  const user = yield select(makeSelectUsers());
  if (user.initialized && user.current.isAuthenticated) {
    const api = yield select(makeSelectApi());
    try {
        if (issue && typeof issue.id !== 'undefined') {
          const result = yield api.get(`documents/documents/issue/${issue.id}`);
          yield put(loadUserDocuments(result));
        }
    } catch (err) {
      console.error('Error fetching issues', err);
    }
}
}

function* getDocumentUrl({document}) {
  const user = yield select(makeSelectUsers());
  if (user.initialized && user.current.isAuthenticated) {
    const api = yield select(makeSelectApi());
    try {
        const result = yield api.get(`documents/user/documents/${document.id}/url`);
        window.open(result.url,'_blank');
    } catch (err) {
      console.error('Error fetching issues', err);
    }
  }
}

function* clickingDocument({document}) {
  const user = yield select(makeSelectUsers());
  if (user.initialized && user.current.isAuthenticated) {
    const api = yield select(makeSelectApi());
    try {
        const result = yield api.post(`documents/documents/clickable/${document.id}`);
    } catch (err) {
      console.error('Error fetching issues', err);
    }
  }
}


export default function* tradeviewSaga() {
  yield call(setIssuesData)
  const { variables } = yield select(makeSelectEnvVariablesData());
  const API_URL = `${wpurl(variables)}/${API_ENPOINT_PREFIX}`; // wordpressURL
  // yield call(getTradeviewContent, API_URL);
  // yield call(getSinglePropertyContent, API_URL);
  // yield call(getKycWpContent, API_URL);
  const user = yield select(makeSelectUsers());
  if (user.initialized) {
    yield call(loadRegistrationdata);
    yield call(getUserDocuments);
  }

  // yield takeLatest(
  //   [LOCATION_CHANGE, CHANGE_LOCALE],
  //   getTradeviewContent,
  //   API_URL,
  // );
  yield takeLatest(SUBMIT_WATCHLIST, addWatchlist);
  yield takeLatest(REMOVE_WATCHLIST, removeWatchlist);
  yield takeLatest(SUBMIT_REVIEW_ORDER, handleReviewOrder);
  yield takeLatest(SUBMIT_FORM_DATA, handleSubmit);
  yield takeLatest(
    [LOCATION_CHANGE, CHANGE_LOCALE],
    setIssuesData
  );
  yield takeLatest(
    [TOGGLE_DOWNLOAD],
    getDocumentUrl
  );
  // yield takeLatest(
  //   [LOCATION_CHANGE, CHANGE_LOCALE],
  //   getSinglePropertyContent,
  //   API_URL,
  // );
  // yield takeLatest(LOCATION_CHANGE, getKycWpContent, API_URL);
  yield takeLatest(SIGN_OUT_SUCCESS, unassignUser);
  yield takeLatest(LOCATION_CHANGE, loadRegistrationdata);
  yield takeLatest(LOCATION_CHANGE, getUserDocuments);
  yield takeLatest(LOAD_ISSUE_DATA, getUserDocuments);
  yield takeLatest(LOCATION_CHANGE, changeStage);
  yield takeLatest(USER_LOADED, loadRegistrationdata);
  yield takeLatest(USER_LOADED, getUserDocuments);
  yield takeLatest(SERVER_EVENT, resetReviewData);
  yield takeLatest(CLICKED_DOCUMENT, clickingDocument);

}
