/* eslint-disable no-new */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import Card from 'components/Card';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from 'components/Grid';
import Seo from 'components/Seo';
import Loader from 'components/Loader';
import Elements from 'components/Elements';
import ListLayout from 'components/List/ListLayout';
import Icon from 'components/Icon';
import Button from 'components/Button';
import FormLayout from 'components/FormLayout';
import PrimaryModal from 'components/PrimaryModal';
import Toast from 'components/Toast';
import { Container, Row, Col } from 'react-bootstrap';
import { getStatusVariant } from 'utils/detection';
import { handleKycButton } from 'utils/kyc';
import {
  required,
  email,
  phoneNumber,
  dob,
  postalCode,
  sin,
  notallzero,
  pdfFile,
  percentage
} from 'utils/validation';
import {
  makeSelectLocale,
  makeSelectForm,
  makeSelectLookups,
  makeSelectApi,
  makeSelectEnvVariablesData,
} from 'containers/App/selectors';
import { push } from 'connected-react-router';
import Sidepanel from 'components/Sidepanel';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { makeSelectDashboardData } from './selectors';
import {
  setToast,
  toggleKycTab,
  toggleSidepanel,
  triggerCtaButton,
  setTruliooModal,
  setTruliooExpId,
  setTruliooLoader,
  setAccountType,
  setJSONData,
  setLoader,
  initiatePlaid
} from './actions';
import reducer from './reducer';
import saga from './saga';
import PlaidIdentity from 'components/PlaidIdentity';
const key = 'dashboard';
function execFn(fnName, ctx, params) {
  if (params) {
    return ctx[fnName](...params);
  }
  return ctx[fnName];
}
const stateSelector = createStructuredSelector({
  auth: makeSelectAuth(),
  locale: makeSelectLocale(),
  dashboardData: makeSelectDashboardData(),
  formData: makeSelectForm(),
  lookups: makeSelectLookups(),
  api: makeSelectApi(),
  env: makeSelectEnvVariablesData(),
  users: makeSelectUsers(),
});
const Dashboard = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const { auth, dashboardData, locale, lookups, api, env, users } = useSelector(
    stateSelector,
  );
  const {
    tabStatus,
    sidepanel,
    sidepanelContent,
    registration,
    selectedTab,
    pdf,
    toast,
    apiresponse,
    wpFinContent,
    accountType,
    data,
    loading,
    activatePlaid
  } = dashboardData;
  const currentStep = selectedTab;

  const [savedData, setSavedData] = useState(false);
  const topRef = useRef(null);
  useEffect(() => {
    const loadData = async () => {
      setLoader(true);
      try {
        const someData = await import(`./dashboard_${locale}.json`);
        const dataBasedOnPath = someData.default.find(
          dt => dt.step_keyname === currentStep,
        );
        dispatch(setJSONData(dataBasedOnPath));
      } catch (error) {
        console.error('Failed to load data:', error);
      } finally {
        setLoader(false);
      }
    };
  
    loadData();
  }, [currentStep, locale, dispatch]);
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep]);
  const getDataBasedOnSelectedTab = data;

  useEffect(() => {
    if (registration) {
      for (const [someKey, value] of Object.entries(registration)) {
        if (currentStep === someKey) {
          if (value) {
            const filteredYesValues = Object.keys(value).map(item =>
              typeof value[item] === 'string' &&
              !value[item].includes('Yes,') &&
              value[item].includes('Yes')
                ? { [item]: `Yes${item}` } // This is needed to set Checkbox as it's value in wordpress is YesnotTaxResiOfCanada but registration Json has only Yes
                : { [item]: value[item] },
            );
            const filteredNoValues = Object.keys(value).map(item =>
              typeof value[item] === 'string' &&
              !value[item].includes('No,') &&
              value[item].includes('No')
                ? { [item]: `No${item}` } // This is needed to set Checkbox as it's value in wordpress is YesnotTaxResiOfCanada but registration Json has only Yes
                : { [item]: value[item] },
            );
            setSavedData(Object.assign({}, ...filteredYesValues, ...filteredNoValues));
          } else {
            setSavedData({});
          }
        }
      }
    }
  }, [currentStep, registration]);

  // useEffect(() => {
  //   function handleResponse(e) {
  //     dispatch(setTruliooExpId(e)); // storing experience id
  //     dispatch(setTruliooLoader(true));
  //   }
  //   const publicKey = '428cff53e184451e9e335bd36b939914'; // Old Sdk key TODO: env files Live key
  //   // const publicKey = '90eb0aa05ba74d2ca7dd41ce420264a1'; // New Sdk key
  //   const accessTokenURL = api.getUrl('kyc/document/verification');
  //   new TruliooClient({
  //     publicKey,
  //     accessTokenURL,
  //     handleResponse,
  //   });
  // }, [apiresponse]);

  const intercept = col =>
    // TOOD: to show status on the dashboard page instead of redirecting.
    // if (sectionsComplete ) {
    //   if (col.class == 'x-gs-1') {
    //     if (registration.status === 'INPROGRESS') {
    //       return [{
    //         id: 'verify_identity',
    //         columns: [{
    //           content: <>Your identity has not been verified.</>
    //         }]
    //       }]
    //     }
    //     else {
    //       return [{
    //         id: 'waiting',
    //         columns: [{
    //           content: <>You have completed everything here and approval is pending.</>
    //         }]
    //       }]
    //     }
    //   }
    //   if (col.class == 'x-gs-2') {
    //     return []
    //   }
    // }
    null;

  const location = useLocation();
  const currentURL = `${env.variables.DOMAIN}${location.pathname}`;
  if (loading) {
    return <Loader />;
  }
  // if (!getDataBasedOnSelectedTab || !registration || !users.initialized) {
  //   return (
  //     <>
  //       <Loader />
  //       {toast && (
  //         <>
  //           <Container
  //             style={{
  //               position: 'fixed',
  //               left: 15,
  //               top: 55,
  //               zIndex: 3000,
  //             }}
  //           >
  //             <Toast
  //               autohide
  //               priority="low"
  //               show={toast}
  //               delay={3000}
  //               message={toast}
  //               onClose={() => dispatch(setToast(false))}
  //             />
  //           </Container>
  //         </>
  //       )}
  //     </>
  //   );
  // }
  return (
    <aside>
      <section>
        <Seo title="Questionnaire" />
        <div className="pt-4" ref={topRef}>
          {getDataBasedOnSelectedTab &&
            getDataBasedOnSelectedTab.section &&
            getDataBasedOnSelectedTab.section.map((item, ind) => (
              <Grid
                key={`Grid${ind}`}
                hasbanner={false}
                container={
                  item.container && [
                    ...item.container.map(cont => ({
                      type: cont.type,
                      class:
                        getDataBasedOnSelectedTab.step_keyname ===
                        'gettingstarted'
                          ? cont.class
                          : 'pt-9 mt-7',
                      key: cont.id,
                      rows: [
                        ...cont.rows.map(row => ({
                          id: `rows${row.id}`,
                          class: row.class,
                          columns: row.columns && [
                            ...row.columns.map(col => ({
                              id: `${col.id}`,
                              class: col.class,
                              widths: col.widths && JSON.parse(col.widths),
                              content: (
                                <>
                                  {col.cardlayout ? (
                                    <Container className="bg-greys-light pt-9">
                                      <Card
                                        className="h-100 w-100"
                                        hover
                                        style={{ borderRadius: '100px' }}
                                        // onClick={() => segmentTrack(col.events)}
                                        onClick={() => {
                                          dispatch(
                                            setAccountType(col.choice_name),
                                          );
                                        }}
                                      >
                                        <Button
                                          className="h-100 w-100 p-0 border-0 text-white"
                                          variant="primary"
                                          placing="card"
                                        >
                                          <Card.Body rotate={false} height="95px">
                                            <Card.Body.Left className="m-auto w-65 px-0">
                                              <div>
                                                {col.paragraphs &&
                                                  col.paragraphs.map(
                                                    (para, pinx) => (
                                                      <Elements
                                                        key={`elements2-${para.id}`}
                                                        tag={para.tag}
                                                        className={
                                                          pinx === 0
                                                            ? `card-text ${para.class}`
                                                            : `card-title ${para.class}`
                                                        }
                                                      >
                                                        {para.content}
                                                      </Elements>
                                                    ),
                                                  )}
                                              </div>
                                            </Card.Body.Left>
                                          </Card.Body>
                                        </Button>
                                      </Card>
                                    </Container>
                                  ) : (
                                    col.paragraphs &&
                                    col.paragraphs.map(para => (
                                      <Elements
                                        key={para.id}
                                        tag={para.tag}
                                        className={para.class}
                                        iconClick={() =>
                                          dispatch(
                                            toggleSidepanel(
                                              true,
                                              col.info_icon_blurb,
                                            ),
                                          )
                                        }
                                      >
                                        {para.content === 'Questionnaire'
                                          ? `${
                                              accountType === 'INDIVIDUAL'
                                                ? 'Personal Account'
                                                : 'Business Account'
                                            } </br>${para.content}`
                                          : para.content}
                                      </Elements>
                                    ))
                                  )}
                                  {col.hasPlaid && <PlaidIdentity token={registration.identityVerification.linkToken} btnfun={(v) => dispatch(
                                          execFn(
                                            col.plaidFun,
                                            {
                                              initiatePlaid,
                                            },
                                            [v],
                                          ),
                                        )}/> }
                                  {col.haslist && (
                                    <>
                                      <hr className="mb-0" />
                                      <ListLayout
                                        rawdata={[
                                          ...col.lists
                                            .filter(
                                              para =>
                                                para.accountType ===
                                                accountType,
                                            )
                                            .map((list, listin) => ({
                                              class: 'pt-3',
                                              id: `listrow-${list.id}`,
                                              columns: [
                                                {
                                                  id: `listcolSection1-${listin}`,
                                                  content: (
                                                    <>
                                                      {list.paragraphs &&
                                                        list.paragraphs.map(
                                                          (para, paraid) => (
                                                            <Elements
                                                              key={paraid}
                                                              tag={para.tag}
                                                              role={
                                                                (tabStatus[
                                                                  list.id
                                                                ] >= 0 ||
                                                                  list.id ===
                                                                    'personal') &&
                                                                para.role
                                                              }
                                                              tabIndex="0"
                                                              onClick={() =>
                                                                (tabStatus[
                                                                  list.id
                                                                ] >= 0 ||
                                                                  list.id ===
                                                                    'personal') &&
                                                                list.hasstatusicon &&
                                                                dispatch(
                                                                  toggleKycTab(
                                                                    `${list.id}`,
                                                                  ),
                                                                )
                                                              }
                                                              className={
                                                                para.class
                                                              }
                                                            >
                                                              {para.content}
                                                            </Elements>
                                                          ),
                                                        )}
                                                    </>
                                                  ),
                                                },
                                                {
                                                  id: `listcolSection2-${listin}`,
                                                  content: (
                                                    <>
                                                      {list.hasstatusicon && (
                                                        <Icon
                                                          variant={getStatusVariant(
                                                            tabStatus[list.id],
                                                          )}
                                                          className="d-flex flex-row-reverse"
                                                          role="button"
                                                          size="xlarge"
                                                          onClick={() =>
                                                            (tabStatus[
                                                              list.id
                                                            ] >= 0 ||
                                                              list.id ===
                                                                'personal') &&
                                                            list.hasstatusicon &&
                                                            dispatch(
                                                              toggleKycTab(
                                                                `${list.id}`,
                                                              ),
                                                            )
                                                          }
                                                          aria-labelledby="Button Click"
                                                        />
                                                      )}
                                                    </>
                                                  ),
                                                },
                                              ],
                                            })),
                                        ]}
                                      />
                                      <hr className="mt-0" />
                                    </>
                                  )}
                                  {col.backbutton &&
                                    col.backbutton.map((btn, index) => (
                                      <>
                                        <Button
                                          name={btn.name}
                                          iconOnly={btn.iconOnly}
                                          icon={btn.icon}
                                          key={index}
                                          type={btn.type}
                                          variant={btn.variant}
                                          className={btn.class}
                                          onClick={() =>
                                            dispatch(
                                              triggerCtaButton(
                                                selectedTab,
                                                false,
                                                'previous',
                                              ),
                                            )
                                          }
                                        >
                                          {btn.label}
                                        </Button>
                                      </>
                                    ))}
                                </>
                              ),
                              container: [
                                {
                                  type: 'fluid',
                                  rows:
                                    intercept(col) ||
                                    (col.rows && [
                                      ...col.rows.map(subrow => ({
                                        id: subrow.id,
                                        class: subrow.class,
                                        columns: subrow.columns && [
                                          ...subrow.columns.map(subcol => ({
                                            id: subcol.id,
                                            class: subcol.class,
                                            widths:
                                              subcol.widths &&
                                              JSON.parse(subcol.widths),
                                            content: (
                                              <>
                                                {subcol.buttons &&
                                                  subcol.buttons.map(
                                                    (btn, index) => (
                                                      <>
                                                        <Button
                                                          key={index}
                                                          type={btn.type}
                                                          variant={btn.variant}
                                                          className={`${btn.class} w-100 animated animatedFadeInUp fadeInUp`}
                                                          onClick={() =>
                                                            btn.link !== ''
                                                              ? dispatch(
                                                                  push(
                                                                    btn.link,
                                                                  ),
                                                                )
                                                              : dispatch(
                                                                  triggerCtaButton(
                                                                    selectedTab,
                                                                    false,
                                                                    btn.kind,
                                                                  ),
                                                                )
                                                          }
                                                        >
                                                          {btn.label}
                                                        </Button>
                                                      </>
                                                    ),
                                                  )}
                                              </>
                                            ),
                                          })),
                                        ],
                                      })),
                                    ]),
                                },
                              ],
                            })),
                          ],
                        })),
                      ],
                    })),
                  ]
                }
              />
            ))}
          {getDataBasedOnSelectedTab &&
            getDataBasedOnSelectedTab.step_keyname !== 'gettingstarted' &&
            getDataBasedOnSelectedTab.section.map(sect => (
              <>
                {sect.container.map(contr => (
                  <Container className={contr.class}>
                    {contr.rows.map(row => (
                      <Row className={row.class}>
                        {row.columns.map(col => (
                          <Col md={8} sm={12} xs={12}>
                            {col.hasform && (
                              <FormLayout
                                submit="allValues"
                                wpFinContent={wpFinContent}
                                hasBackButton
                                backButtonProperties={{
                                  className: '',
                                  functionname: 'triggerCtaButton',
                                  context: { triggerCtaButton },
                                  dispatch,
                                  funcparams: [selectedTab, false, 'previous'],
                                }}
                                currentStep={selectedTab}
                                forms={col.formdata}
                                initialValuesOfForm={{
                                  ...(col.formdata.initialvalues &&
                                    Object.assign(
                                      {},
                                      ...col.formdata.initialvalues.map(
                                        acc => ({
                                          [acc.key]: acc.value,
                                        }),
                                      ),
                                    )),
                                  ...Object.fromEntries(
                                    Object.entries(savedData).filter(
                                      // eslint-disable-next-line no-unused-vars
                                      ([_, v]) => v != null,
                                    ),
                                  ),
                                }}
                                pdf={pdf}
                                dropDownOptions={v =>
                                  v.name === 'taxResidentCountry'
                                    ? lookups.data
                                    : false
                                }
                                submitFunction={v =>
                                  dispatch(
                                    // NOTE: If button type is submit, it will always be kind: next
                                    triggerCtaButton(
                                      selectedTab,
                                      v.data,
                                      'next',
                                    ),
                                  )}
                                iconClick={v =>
                                  dispatch(toggleSidepanel(true, v))
                                }
                                context={{
                                  phoneNumber,
                                  required,
                                  postalCode,
                                  email,
                                  dob,
                                  sin,
                                  notallzero,
                                  pdfFile,
                                  percentage,
                                  handleKycButton,
                                }}
                                buttonFunctionArray={[
                                  dispatch,
                                  triggerCtaButton,
                                ]}
                                apiErrorMessage=""
                              />
                            )}
                          </Col>
                        ))}
                      </Row>
                    ))}
                  </Container>
                ))}
              </>
            ))}
        </div>
        {/* Trulioo Modal */}
        {/* {trulioo &&
          getDataBasedOnSelectedTab.section.map(sect => (
            <>
              {sect.container &&
                sect.container.map(contr => (
                  <Container>
                    {contr.rows.map(row => (
                      <Row className={row.class}>
                        {row.columns.map(col => (
                          <Col md={8} sm={12} xs={12}>
                            {col.hasmodal && (
                              <>
                                {col.modals.map(
                                  modal =>
                                    modal.id === trulioo.id && (
                                      <PrimaryModal
                                        show={trulioo}
                                        close={() =>
                                          dispatch(setTruliooModal(false))
                                        }
                                        modalTitle={modal.title}
                                        hideClose
                                        body={
                                          <>
                                            {truliooLoader && (
                                              <>
                                                <Row className="justify-content-center">
                                                  Hold on tight, while we verify
                                                  your information
                                                </Row>
                                                <Row className="justify-content-center">
                                                  <Col
                                                    md={1}
                                                    className="align-items-center"
                                                  >
                                                    <div
                                                      style={{
                                                        position: 'fixed',
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0,
                                                        zIndex: 5000,
                                                      }}
                                                    ></div>
                                                    <div
                                                      className="d-flex align-items-center"
                                                      style={{
                                                        position: 'absolute',
                                                        left: '50%',
                                                        top: 25,
                                                        transform:
                                                          'translate(-50%, -50%)',
                                                      }}
                                                    >
                                                      <div
                                                        className="spinner-border text-success"
                                                        role="status"
                                                      >
                                                        <span className="visually-hidden">
                                                          Loading...
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </>
                                            )}
                                            {!truliooLoader && (
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: modal.content,
                                                }}
                                              ></div>
                                            )}

                                            {modal.buttons &&
                                              modal.buttons.map(btn => (
                                                <Button
                                                  type={btn.type}
                                                  variant={btn.variant}
                                                  className={btn.class}
                                                  onClick={() => {
                                                    dispatch(
                                                      setTruliooModal(false),
                                                    );
                                                    dispatch(push(btn.link));
                                                  }}
                                                >
                                                  {btn.label}
                                                </Button>
                                              ))}
                                          </>
                                        }
                                      />
                                    ),
                                )}
                              </>
                            )}
                          </Col>
                        ))}
                      </Row>
                    ))}
                  </Container>
                ))}
            </>
          ))} */}
        {sidepanel && (
          <Sidepanel
            isVisible={sidepanel}
            toggleSidepanel={() => dispatch(toggleSidepanel(false, false))}
          >
            {sidepanelContent}
          </Sidepanel>
        )}
        {toast && (
          <>
            <Container
              style={{
                position: 'fixed',
                left: 15,
                top: 55,
                zIndex: 3000,
              }}
            >
              <Toast
                autohide
                priority="low"
                show={toast}
                // delay={3000}
                message={toast}
                onClose={() => dispatch(setToast(false))}
              />
            </Container>
          </>
        )}
      </section>
    </aside>
  );
};

export default {
  component: Dashboard,
};
