/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import Card from 'components/Card';
import { Col } from 'react-bootstrap';

import MainSection from './mainSection';
import RateSection from './rateSection';
import TradeAreaSection from './tradeareaSection';
import HeaderSection from './headerSection';
import AccordianSection from './accordianSection';

const CenterPanel = ({
  rawdata,
  toggleMainTab,
  activeMainTab = 'Overview',
  activeTradeTab,
  toggleTradeTab,
  activeCapsule,
  toggleCapsule,
  propertyDetail,
  submitWatchlist,
  removeWatchlist,
  symbol,
  holdings,
  watchList,
  authenticationStatus,
  locale,
  propertyContent, // this has name and symbol
  lat,
  lng,
  userDocuments,
  toggleDownload,
  clickDocument
}) => {
  useEffect(() => {
    toggleMainTab(rawdata.default_main_tab);
  }, [rawdata]);
  return (
    <Col md={7} lg={7} xl={8} className="my-1" id={Math.random()}>
      {propertyDetail && (
        <HeaderSection
          data={propertyDetail.detail}
          rawdata={rawdata}
          propertyContent={propertyContent.detail}
          symbol={symbol}
          submitWatchlist={submitWatchlist}
          removeWatchlist={removeWatchlist}
          watchList={watchList}
          authenticationStatus={authenticationStatus}
          locale={locale}
        />
      )}
      <MainSection
        lat={lat}
        lng={lng}
        locale={locale}
        tabs={rawdata.main_tab_section}
        defaultTab={activeMainTab}
        toggleMainTab={toggleMainTab}
        tradeImages={propertyContent && propertyContent.images}
        numberOfImages={ propertyContent && 
         typeof propertyContent.images !== 'undefined' && propertyContent.images.filter(e => e.category !== 'neighbourhood').length
        }
        data={
          activeMainTab
            ? rawdata.main_tab_section.find(
                item => activeMainTab === item.header,
              )
            : rawdata.main_tab_section.find(
                item => rawdata.default_main_tab === item.header,
              )
        }
        propertyDetail={propertyDetail && propertyDetail}
        // primaryOfferingLabel={rawdata && rawdata.offeringlabel1}
        offeringLabel={propertyDetail && typeof propertyDetail.offeringLabel !== 'undefined' && propertyDetail.offeringLabel}
      />

      <RateSection
        data={propertyDetail && typeof propertyDetail.rateSection && propertyDetail.rateSection}
        infobubble={propertyDetail && typeof propertyDetail.infobubbletext && propertyDetail.infobubbletext}
      />

      <Card
        style={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <TradeAreaSection
          propertyDetail={propertyDetail && propertyDetail}
          activeTradeTab={activeTradeTab}
          tabs={rawdata.trade_tab_section}
          defaultTab={rawdata.default_trade_tab}
          toggleTradeTab={toggleTradeTab}
          data={propertyDetail && propertyDetail.detailsSection}
        />
      </Card>
      <hr className="mt-6"></hr>

      <AccordianSection
        symbol={symbol}
        data={propertyDetail && propertyDetail.accordionSection}
        propertyDetail={propertyDetail && propertyDetail}
        activeCapsule={activeCapsule}
        toggleCapsule={toggleCapsule}
        propertyContent={propertyContent}
        holdings={holdings}
        locale={locale}
        userDocuments={userDocuments}
        authenticationStatus={authenticationStatus}
        toggleDownload={toggleDownload}
        clickDocument={clickDocument}
      />
    </Col>
  );
};

export default CenterPanel;
