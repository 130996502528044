/* eslint-disable react/prop-types */
import React from 'react';
import Link from 'components/Link';
import Icon from 'components/Icon';
import Tooltip from 'components/TooltipV1';

const Elements = ({
  tag,
  className,
  children,
  hasLink,
  linkprops,
  role,
  onClick,
  iconClick,
  iconsize,
  properties,
  replaceTokens,
  tokens,
}) => {
  const TagName = tag || 'p';
  if (TagName === 'hr') {
    return <TagName className={className} />;
  }
  if (TagName === 'icon') {
    return (
      <Icon
        variant={children}
        aria-hidden="true"
        onClick={iconClick}
        size={iconsize || 'small'}
        className={className}
      />
    );
  }
  if (TagName === 'tooltip') {
    return (
      <Tooltip
        placement={properties.tooltip.placement}
        size={properties.tooltip.size}
        buttonProps={{
          ...properties.tooltip.buttonProps,
          label:
            properties.tooltip.buttonProps.label === 'GSRFCODE'
              ? replaceTokens('GSRFCODE', tokens)
              : properties.tooltip.buttonProps.label,
        }}
        message={children}
      />
    );
  }

  return (
    <>
      <TagName
        className={hasLink ? `d-inline ${className}` : className}
        role={role && role}
        onClick={onClick}
        dangerouslySetInnerHTML={{ __html: children }}
      />
      {hasLink && (
        <Link
          onClick={() => linkprops.function()}
          to={linkprops.to}
          nodecoration={linkprops.nodecoration}
          variant={linkprops.variant}
        >
          {linkprops.content}
          <span className="visually-hidden visually-hidden-focusable">
            {linkprops.content}
          </span>
        </Link>
      )}
    </>
  );
};

export default Elements;
