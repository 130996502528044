/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import NotFound from 'components/NotFound';
import Toast from 'components/Toast';
import CanadaMap from 'components/CanadaMap';
import Logo from 'components/Logo';
import { Row, Col, Container } from 'react-bootstrap';
import Link from 'components/Link';
import Grid from 'components/Grid';
import Curve from 'components/Curve';
import Icon from 'components/Icon';
import { push } from 'connected-react-router';
import Loader from 'components/Loader';
import {
  makeSelectLocale,
  makeSelectFooterState,
  makeSelectTradeProperties,
  makeSelectEnvVariablesData,
} from 'containers/App/selectors';
import Toggle from 'components/Toggle';
import ListLayout from 'components/List/ListLayout';
import PortfolioPiechart from 'components/Chart/PortfolioPieChart';
import DefaultChart from 'components/Chart/DefaultChart';
import Card from 'components/Card';
import Flag from 'components/Flag';
import { makeSelectUsers } from 'containers/App/Users/selectors';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import Seo from 'components/Seo';
import {
  authChanged,
  triggerAuthTracker,
  signOutOnAllDevices,
  authFailed,
  storePhoneVerificationData, // Don't remove this, wordpress has this value and is used for context
  requireSmsVerification,
} from 'containers/AuthProvider/actions';
import {
  toggleVerificationModal,
  toggleCredentialsModal,
  toggleCapsule,
} from 'containers/AccountPage/actions';
import { sendVerification } from 'utils/firebase';
import { isServer } from 'utils/detection';
import moment from 'moment';
import Footer from 'components/Footer';
import WillowButton from 'components/Button';
import Tooltip from 'components/TooltipV1';
import { loadPortfolioPageDataSuccess, setPorfolioPageToast } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectPortfolioPage } from './selectors';
import { formatters } from '../../utils/formatters';
import PORTIMG from './portfolio.jpg';
const key = 'portfolio';
const stateSelector = createStructuredSelector({
  locale: makeSelectLocale(),
  pagedata: makeSelectPortfolioPage(),
  users: makeSelectUsers(),
  auth: makeSelectAuth(),
  footerState: makeSelectFooterState(),
  tradeProperties: makeSelectTradeProperties(),
  env: makeSelectEnvVariablesData(),
});
function execFn(fnName, ctx, params) {
  if (params) {
    return ctx[fnName](...params);
  }
  return ctx[fnName];
}
const Portfolio = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const {
    locale,
    pagedata,
    users,
    auth,
    footerState,
    tradeProperties,
    env,
  } = useSelector(stateSelector);
  const [view, setView] = useState('list');
  
  useEffect(() => {
    // setLoader(true);
    const loadData = async () => {
      const someData = await import(`./portfolio_${locale}.json`);
      const dataBasedOnPath = someData.default;
      dispatch(loadPortfolioPageDataSuccess(dataBasedOnPath));
      // setLoader(false);
    };
    loadData();
  }, []);

  const propertyData = tradeProperties.data.results;
  const context = {
    sendVerification,
  };
  const userProperties = pagedata.wpcontent.purchases.results;

  const upsym = userProperties && userProperties.properties;

  const userSymbols = upsym ? upsym.map(e => e.symbol) : [];

  const gridviewData =
    propertyData &&
    typeof propertyData !== 'undefined' &&
    propertyData
      .filter(e => userSymbols.indexOf(e.symbol) > -1)
      .map((item, i) => ({
        ...item,
        ...(upsym && upsym.filter(e => e.symbol === item.symbol)[0]),
      }));

  const userName = users && users.current && users.current.firstName;

  const areaData =
    userProperties && userProperties.propertyPerformanceLocationWise;

  const assetCount = {};
  const assetNames = [];

  const assetPer =
    gridviewData &&
    gridviewData.map(as => {
      if (!assetCount[as.category]) {
        assetNames.push(as.category);
        assetCount[as.category] = {};
        assetCount[as.category].name = as.category;
        assetCount[as.category].count = 0;
      }
      assetCount[as.category].count++;
    });

  // const assetP = assetNames.map(asn => ({
  //   propertyType: asn,
  //   assetPerformance: (assetCount[asn].count / gridviewData.length) * 100,
  // }));

  // This code was renedered useless by the algorithm implemented below, it wasn't deleted as it wasn't my code.

  // const totalFunds =
  //   parseFloat(
  //     users &&
  //       users.current &&
  //       users.current.account &&
  //       users.current.account.portfolioValue,
  //   ) +
  //   parseFloat(
  //     users &&
  //       users.current &&
  //       users.current.account &&
  //       users.current.account.availableFunds,
  //   );

  // This algorithm was created to solve the issue of the percentages in the portfolio page not adding up to 100% (commonly adding to 99.99% or 100.01%), this happens due to rounding to 2 decimal places.

  // First defined are some helper functions, that are required for the actual algorithm to run. 
  // sumArray uses the reduce method to sum the values in an array.
  // percentConverter converts an array of values into the percent that each value is of the total (i.e. [1000, 1000, 2000] => [25, 25, 50])
  // normalRound rounds an array of percentage values to 2 decimal places
  // getAdjustmentValue returns a value of the number of rounds that must be flipped, and what direction (i.e. sum of normalRound = 99.99 => getAdjustmentValue returns -1, sum of normalRound = 100.02 => getAdjustmentValue returns 2) (negative implies value that was rounded down must be rounded up, and vice verse for positive)
  // getListDifference gets the difference between the rounded value, and the raw value (56.56700 => 56.57 has a difference of 0.00300, which is what this function returns)
  // remove value takes in a nested array, and removes the second value in the nested arrays, making it a normal array (i.e. [[1,2], [3,4]] => [1,3]) 

  const sumArray = listContent => listContent.reduce((acc, cur) => acc += cur, 0);
  const percentConverter = listContent => listContent.map(value => 100*value/sumArray(listContent));
  const normalRound = listContent => listContent.map(percent => Math.round(100*percent)/100);
  const getAdjustmentValue = percentList => Math.round(100 * (sumArray(normalRound(percentList)) - 100));
  const getListDifference = (listContent, index) => normalRound(listContent)[index] - listContent[index];
  const removeValue = valueIndices => valueIndices.map(valueIndex => valueIndex[0]);

  // The algorithm sets up an empty list called valueIndices (because it stores the difference value and the index of that value), then cycles through each raw percent value, and finds the difference between the raw value and the rounded value, and stores the largest differences inside that list. The length of this list is the absolute value of the result of getAdjustmentValue, and the differences are negative if the value from getAdjustment value is negative, and positive if positive. At the very end, a nested array of the differences and indices are produced, and the differences are removed, to produce just a list of indices in which the rounding must be flipped.
  // finalRound simply does everything at once, and uses this list of indices to flip the rounding on some values, producing an array of the final values.

  const getValueIndices = (listContent, listType) => {
    const valueIndices = [];
    listContent.forEach((value, index) => {
        if (valueIndices.length < Math.abs(listType)) {
            valueIndices.push([index, getListDifference(listContent, index)]);
        } else {
            let difference = getListDifference(listContent, index);
            for (let i = 0; i < valueIndices.length; i++) {
                if (listType < 0) {
                    valueIndices.sort((a,b) => {return b[1]-a[1]});
                    if (valueIndices[i][1] > difference) {
                        valueIndices[i] = [index, difference];
                        break;
                    }
                } else {
                    valueIndices.sort((a,b) => {return a[1]-b[1]});
                    if (valueIndices[i][1] < difference) {
                        valueIndices[i] = [index, difference];
                        break;
                    }
                }
            }
        }
    });
    return removeValue(valueIndices);
  };

  const finalRound = (portfolioValues) => {
    const percentList = percentConverter(portfolioValues);
    const listType = getAdjustmentValue(percentList);
    const indices = getValueIndices(percentList, listType);
    return percentList.map((value, index) => {
      return (indices.includes(index) ? ((listType < 0) ? Math.ceil(100*value)/100 : Math.floor(100*value)/100) : Math.round(100*value)/100);
    });
  };

  // portfolioObjects is created to map the properties in gridviewData to the correct indices in the list. portfolioValues is all of the numerical values of the cash and properties, finalPercent is the list of final values that should be outputted assetP was changed to se the ternary operator to produce either the index of the property/cash, or NaN, depending on whether or not finalPercent existed yet. This was done to fit the output of the previous design. parseFLoat is used to make sure that there is no error with NaN being outputted (again, with the previous design).

  const portfolioObjects = 
    users &&
    users.current &&
    users.current.account &&  
    users.current.userProperties.properties
      .concat([users.current.account]);

  const portfolioValues = 
    users &&
    users.current &&
    users.current.account &&  
    users.current.userProperties.properties
      .map(prop => prop.bookValue)
      .concat([users.current.account.availableFunds]);

  const finalPercent = 
    users &&
    users.current &&
    users.current.account && 
    finalRound(portfolioValues);

  const assetP = gridviewData && [
    ...gridviewData.map(asn => ({
      propertyType: asn.category,
      location: asn.propertyLocation,
      cash:
        parseFloat((finalPercent) ? finalPercent[finalPercent.length-1] : NaN),
      assetPerformance: 
        parseFloat((finalPercent) ? finalPercent[portfolioObjects.findIndex(property => property.propertyName === asn.propertyName)] : NaN),
    })),
    {
      assetPerformance:
        parseFloat((finalPercent) ? finalPercent[finalPercent.length-1] : NaN),
      propertyType: 'CASH',
      location: 'Cash',
    },
  ];

  const watchList = users && users.current && users.current.watchlist;

  const watchlistProperties =
    propertyData &&
    typeof propertyData !== 'undefined' &&
    propertyData.filter(
      item => watchList && watchList.indexOf(item.symbol) >= 0,
    );

  const propertyTransactions =
    users &&
    users.current &&
    users.current.account &&
    users.current.account.propertyTransactions;

  const bankTransactions =
    users &&
    users.current &&
    users.current.account &&
    users.current.account.bankTransactions;

  // const recentTransactions = bankTransactions &&
  //   propertyTransactions && [...bankTransactions, ...propertyTransactions];

  const recentTransaction = bankTransactions && bankTransactions;

  const recentTransactions =
    recentTransaction &&
    [...recentTransaction].sort(
      (x, y) =>
        new moment(y.timestamp).format('YYYYMMDD') -
        new moment(x.timestamp).format('YYYYMMDD'),
    );

  const primarytrades =
    users && users.current && users.current.primaryTradeRequests;

  const primaryTradeRequests =
    primarytrades &&
    Object.entries(primarytrades)
      .map(item => item[1])
      .flat()
      .sort(
        (a, b) =>
          new moment(b.timeStamp).format('YYYYMMDD') -
          new moment(a.timeStamp).format('YYYYMMDD'),
      );

  const seeAll = (blurb, url) => {
    if (blurb === 'Recent Transactions' || blurb === 'Opérations récentes') {
      dispatch(toggleCapsule('transactions'));
      dispatch(push(url));
    } else {
      dispatch(toggleCapsule('trades'));
      dispatch(push(url));
    }
  };

  const location = useLocation();
  const currentURL = `${env.variables.DOMAIN}${location.pathname}`;

  const { registrationStatus } = users.current;
  let folioStatus = registrationStatus;
  if (registrationStatus === 0 || registrationStatus < 2) {
    // Email is not verified and kyc is not completed
    folioStatus = 1;
  }
  if (registrationStatus === 3) {
    // Kyc is approved but user hasn't transferred fund
    if (users.current.account.bankTransactions.length === 0) {
      folioStatus = 2;
    }
  }
  if (registrationStatus === 2) {
    // Kyc is not approved but user has transferred fund
    if (users.current.account.bankTransactions.length > 0) {
      folioStatus = 3;
    }
  }

  if (
    pagedata.wpcontent.loading &&
    // !tradeProperties.data ||
    !users.current.isAuthenticated &&
    !users.initialized &&
    !pagedata.wpcontent.data
  ) {
    console.log('HERE', pagedata.wpcontent);
    return <Loader />;
  }
  if (pagedata.error) {
    return (
      <section className="h-100 min-vh-100 bg-primary d-flex align-items-center">
        <NotFound code="502" />
      </section>
    );
  }

function getStatusMessage(status) {
  switch(status) {
    case 'PENDING_SUBSCRIPTION_DOCS':
      return 'Pending Subscription';
    case 'PENDING_MB':
      return 'Pending MB';
    case 'PENDING_ISSUER':
      return 'Pending Issuer';
    case 'PENDING_FUNDS':
      return 'Pending Funds';
    case 'FUNDED':
      return 'Funded';
    case 'ISSUER_SETTLED':
      return 'Issuer Settled';
    case 'CANCELLED':
      return 'Cancelled';
    default:
      return 'Unknown Status';
  }
};

  return (
    <>
      <Seo title="Portfolio" url={currentURL} />
      {pagedata.wpcontent.data &&
        pagedata.wpcontent.data.section &&
        // tradeProperties.data &&
        pagedata.wpcontent.data.section.map((item, gridkey) => (
          <Grid
            key={`Grid${gridkey}`}
            hasbanner={item.hasbanner}
            bannerproperties={{
              size: 'lg',
              variant: 'willow',
              bgImage:
                folioStatus < 4
                  ? PORTIMG
                  : PORTIMG,
              // curve: false,
              className: 'display-1 m-0',
            }}
            container={[
              {
                class: `${item.backgroundcolor} px-0`,
                type: 'fluid',
                curve: item.hascurve && <Curve className="bg-white" />,
                rows: [
                  {
                    class: 'justify-content-center',
                    columns: [
                      {
                        id: 'portfoliosection1',
                        widths: { md: '12' },
                        content: '',
                        container: [
                          {
                            type: 'fluid',
                            rows: [
                              ...item.rows.map((row, i) => ({
                                id: `porto-${row.id}`,
                                class: row.class,
                                columns: [
                                  ...row.columns.map(col => ({
                                    class: col.class,
                                    widths:
                                      col.widths && JSON.parse(col.widths),
                                    id: `portof-${col.id}`,
                                    content: (
                                      <>
                                        <p
                                          className={`${
                                            col.inbannerlayout &&
                                            folioStatus < 4
                                              ? 'h6 mb-2 pb-0 pt-7 ms-3'
                                              : col.inbannerlayout &&
                                                folioStatus === 4
                                              ? 'h2 ms-3 mt-7'
                                              : 'h2'
                                          }`}
                                        >
                                          {col.inbannerlayout &&
                                          folioStatus === 4
                                            ? `${
                                                col.conditionalHeader
                                              } ${userName || ''}`
                                            : col.inbannerlayout &&
                                              folioStatus < 4
                                            ? `${col.header} ${userName || ''}`
                                            : col.header}
                                        </p>

                                        {/* {col.hasviewtoggle &&
                                        userProperties &&
                                        userProperties.length ? (
                                          <Col className="d-flex justify-content-end">
                                            <Toggle
                                              // disabled={false}
                                              options={[
                                                {
                                                  icon: 'grid',
                                                  value: 'grid',
                                                },
                                                {
                                                  icon: 'list',
                                                  value: 'list',
                                                },
                                              ]}
                                              // defaultValue="grid"
                                              onClick={() => {
                                                if (view === 'list') {
                                                  setView('grid');
                                                } else {
                                                  setView('list');
                                                }
                                              }}
                                              size="lg"
                                            />
                                          </Col>
                                        ) : (
                                          ''
                                        )} */}

                                        {col.blurb && (
                                          <div
                                            className={`${
                                              col.inbannerlayout
                                                ? 'h2 ms-3 mb-0'
                                                : col.inbannerlayout &&
                                                  folioStatus < 4
                                                ? 'h6 mb-2 pb-0 pt-7 ms-3'
                                                : col.inbannerlayout &&
                                                  folioStatus === 4
                                                ? 'h2 ms-3 mt-7'
                                                : ''
                                            }`}
                                          >
                                            {col.inbannerlayout &&
                                            folioStatus === 4 ? (
                                              <>
                                                <Row className="mb-n5">
                                                  {/* <Col
                                                    sm={12}
                                                    md={5}
                                                    lg={3}
                                                    xl={3}
                                                    xxl={2}
                                                    className="mb-0 mb-md-n5"
                                                  >
                                                    <p className="mb-0">
                                                      {col.user_portfolio &&
                                                        col.user_portfolio}
                                                    </p>
                                                    <p className="wallet mt-0">
                                                      {users.current.account &&
                                                        formatters.currency(
                                                          users.current.account
                                                            .portfolioValue,
                                                        )}
                                                    </p>
                                                  </Col> */}
                                                  {/* <Col
                                                    sm={12}
                                                    md={5}
                                                    lg={3}
                                                    xl={3}
                                                    xxl={2}
                                                    className="mb-n5"
                                                  >
                                                    <p className="mb-0">
                                                      {col.user_wallet &&
                                                        col.user_wallet}
                                                    </p>
                                                    <p className="wallet mt-0">
                                                      {users.current.account &&
                                                        formatters.currency(
                                                          users.current.account
                                                            .availableFunds,
                                                        )}
                                                    </p>
                                                  </Col> */}
                                                </Row>
                                              </>
                                            ) : col.inbannerlayout &&
                                              folioStatus === 2 ? (
                                              col.conditionalBlurb
                                            ) : col.inbannerlayout &&
                                              folioStatus < 2 ? (
                                              col.blurb
                                            ) : userProperties &&
                                              userProperties
                                                .length ? (
                                              ''
                                            ) : col.haswatchlist &&
                                              (users.current.watchlist ===
                                                null ||
                                                (users.current.watchlist &&
                                                  users.current.watchlist
                                                    .length == 0)) ? (
                                              col.blurb
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        )}

                                        {col.haslink &&
                                          col.haswatchlist &&
                                          (users.current.watchlist === null ||
                                            (users.current.watchlist &&
                                              users.current.watchlist.length ==
                                                0)) && (
                                            <>
                                              <Link
                                                to={col.linkurl}
                                                className="px-0 mx-0"
                                                variant="cta"
                                                disabled={false}
                                                aria-label={col.linklabel}
                                              >
                                                {col.linklabel}
                                              </Link>
                                            </>
                                          )}

                                        {col.haswatchlist ? (
                                          <>
                                            {watchlistProperties &&
                                              watchlistProperties.map(
                                                (item, i) => (
                                                  <Link
                                                    to={`trade/${item.symbol}`}
                                                    className="m-0 p-0 text-decoration-none"
                                                    key={i}
                                                  >
                                                    <Row className="col-xs-6 col-sm-12">
                                                      <Col className="col-md-5 pe-2">
                                                        <img
                                                          src={
                                                            item.images &&
                                                            item.images.filter(
                                                              e =>
                                                                e.category ==
                                                                'main',
                                                            )[0].url
                                                          }
                                                          className="img-fluid rounded"
                                                          alt={`watchlist properties - ${item.symbol}`}
                                                        />
                                                      </Col>
                                                      <Col className="col-sm-7 pe-3 text-start">
                                                        <p className="w-100 mb-0 text-greys-dark">
                                                          {item.name}
                                                        </p>
                                                        <p className="w-100 mb-0 text-black">
                                                          {item.location}
                                                        </p>
                                                        <p className="w-100 mb-0 text-greys-dark">
                                                          {formatters.currency(
                                                            item.ipoDetails
                                                              .pricePerShare,
                                                          )}
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </Link>
                                                ),
                                              )}
                                          </>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    ),
                                    container: [
                                      {
                                        type: 'fluid',
                                        class: 'px-0',
                                        rows: col.rows && [
                                          ...col.rows.map((subrow, i) => ({
                                            id: `portfoo-${subrow.id}`,
                                            class: subrow.class,
                                            widths:
                                              subrow.widths &&
                                              JSON.parse(subrow.widths),
                                            columns: subrow &&
                                              subrow.columns && [
                                                ...subrow.columns.map(subcol =>
                                                  !subcol.hasstep
                                                    ? {
                                                        widths:
                                                          subcol.widths &&
                                                          JSON.parse(
                                                            subcol.widths,
                                                          ),
                                                        class:
                                                          subcol.hastraderequests &&
                                                          subcol.hasactivity &&
                                                          recentTransactions &&
                                                          recentTransactions.length
                                                            ? ''
                                                            : subcol.class, // adding background
                                                        id: `portfoooo-${subcol.id}`,
                                                        content: (
                                                          <>
                                                            {subcol.buttons &&
                                                              subcol.buttons.map(
                                                                btn => (
                                                                  <React.Fragment
                                                                    key={parseInt(
                                                                      btn.stepid,
                                                                    )}
                                                                  >
                                                                    {parseInt(
                                                                      btn.stepid,
                                                                    ) ===
                                                                      folioStatus && (
                                                                      <WillowButton
                                                                        type="button"
                                                                        variant={
                                                                          btn.variant
                                                                        }
                                                                        onClick={() => {
                                                                          if (
                                                                            btn.value
                                                                          ) {
                                                                            execFn(
                                                                              btn.value,
                                                                              context,
                                                                              [
                                                                                auth.email,
                                                                                triggerAuthTracker,
                                                                                signOutOnAllDevices,
                                                                                auth,
                                                                                requireSmsVerification,
                                                                                toggleVerificationModal,
                                                                                toggleCredentialsModal,
                                                                                authFailed,
                                                                                authChanged,
                                                                                dispatch,
                                                                                {
                                                                                  portfoliopage: true,
                                                                                },
                                                                                setPorfolioPageToast,
                                                                              ],
                                                                            );
                                                                          }
                                                                          if (
                                                                            btn.link
                                                                          ) {
                                                                            dispatch(
                                                                              push(
                                                                                btn.link,
                                                                              ),
                                                                            );
                                                                          }
                                                                        }}
                                                                        className="animated animatedFadeInUp fadeInUp mt-5 ms-2 text-black"
                                                                        style={{
                                                                          animationDelay:
                                                                            '0.8s',
                                                                        }}
                                                                        aria-label={
                                                                          btn.label
                                                                        }
                                                                      >
                                                                        {
                                                                          btn.label
                                                                        }
                                                                      </WillowButton>
                                                                    )}
                                                                  </React.Fragment>
                                                                ),
                                                              )}

                                                            {subcol.hasstep && (
                                                              <>
                                                                {folioStatus <
                                                                  4 && (
                                                                  <>
                                                                    <div
                                                                      className={`${
                                                                        folioStatus >=
                                                                        subcol.stepid
                                                                          ? 'step-border-complete bg-primary border-2 border-primary'
                                                                          : 'step-border'
                                                                      }`}
                                                                    >
                                                                      <p className="h5">
                                                                        {
                                                                          subcol.stepid
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                    <p
                                                                      className={`${
                                                                        subcol.partofbanner
                                                                          ? 'h4 mt-2 mb-3 h-25'
                                                                          : ''
                                                                      }`}
                                                                    >
                                                                      {
                                                                        subcol.header
                                                                      }
                                                                    </p>
                                                                    <p>
                                                                      {
                                                                        subcol.blurb
                                                                      }
                                                                    </p>
                                                                  </>
                                                                )}
                                                              </>
                                                            )}

                                                            {subcol.header &&
                                                              !subcol.hasstep &&
                                                              (subcol.hastraderequests &&
                                                              userProperties &&
                                                              userProperties
                                                                .length ? (
                                                                ''
                                                              ) : (
                                                                <p
                                                                  className={`${
                                                                    subcol.partofbanner
                                                                      ? 'h4 mt-2 mb-3 h-25'
                                                                      : ''
                                                                  }`}
                                                                >
                                                                  {
                                                                    subcol.header
                                                                  }
                                                                </p>
                                                              ))}
                                                            {subcol.image &&
                                                              ((subcol.hastraderequests &&
                                                                userProperties &&
                                                                userProperties
                                                                  .length) ||
                                                              (subcol.hasactivity &&
                                                                recentTransactions &&
                                                                recentTransactions.length) ? (
                                                                ''
                                                              ) : (
                                                                <>
                                                                {subcol.lastSection ? <Logo variant="named" /> : <img
                                                                  src={
                                                                    subcol.image
                                                                  }
                                                                  className={`h-auto pb-4 ${
                                                                    subcol.partofbanner
                                                                      ? 'w-100'
                                                                      : subcol.hasactivity
                                                                      ? 'w-50 w-md-5 pb-4'
                                                                      : subcol.hastraderequests
                                                                      ? 'w-75 w-md-25 me-6 me-md-0'
                                                                      : ''
                                                                  }`}
                                                                  alt="your properties"
                                                                />}
                                                                
                                                                </>
                                                                
                                                              ))}

                                                            {/* {subcol.hasmap && (
                                                              <CanadaMap
                                                                userProperties={
                                                                  userProperties.properties
                                                                }
                                                                allProperties={
                                                                  propertyData
                                                                }
                                                              />
                                                            )} */}
                                                            {subcol.blurb &&
                                                              !subcol.hasstep &&
                                                              ((subcol.hastraderequests &&
                                                                userProperties &&
                                                                userProperties
                                                                  .length) ||
                                                              (subcol.hasactivity &&
                                                                recentTransactions &&
                                                                recentTransactions.length) ? (
                                                                ''
                                                              ) : (
                                                                <Row className="mb-n3">
                                                                  <Col
                                                                    xs={`${
                                                                      subcol.haslink
                                                                        ? '8'
                                                                        : '12'
                                                                    }`}
                                                                  >
                                                                    <p
                                                                      className={`${
                                                                        subcol.haslink
                                                                          ? 'text-black font-bold'
                                                                          : 'text-black'
                                                                      }`}
                                                                    >
                                                                      {
                                                                        subcol.blurb
                                                                      }
                                                                    </p>
                                                                  </Col>
                                                                  <Col
                                                                    xs={`${
                                                                      subcol.haslink
                                                                        ? '4'
                                                                        : ''
                                                                    }`}
                                                                    className="d-flex justify-content-end"
                                                                  >
                                                                    {subcol.haslink &&
                                                                    userProperties &&
                                                                    userProperties
                                                                      .length ? (
                                                                      <>
                                                                        <span
                                                                          // onClick={() => {
                                                                          //   seeAll(
                                                                          //     subcol.blurb,
                                                                          //     subcol.linkurl,
                                                                          //   );
                                                                          // }}
                                                                          key={
                                                                            i
                                                                          }
                                                                          className="text-decoration-underline"
                                                                        >
                                                                          {
                                                                            subcol.linklabel
                                                                          }{' '}
                                                                          <Icon
                                                                            variant="chevronRight"
                                                                            size="sm"
                                                                            color="#4cd683"
                                                                          />
                                                                        </span>

                                                                        {/* This code is kept just incase we change to links going forward
                                                                        <Link
                                                                          to={
                                                                            subcol.linkurl
                                                                          }
                                                                          className="px-0 mx-0"
                                                                          variant="cta"
                                                                          disabled={
                                                                            false
                                                                          }
                                                                          aria-label={
                                                                            subcol.linklabel
                                                                          }
                                                                        >
                                                                          {
                                                                            subcol.linklabel
                                                                          }
                                                                        </Link> */}
                                                                      </>
                                                                    ) : (
                                                                      ''
                                                                    )}
                                                                  </Col>
                                                                </Row>
                                                              ))}

                                                            {subcol.hassinglebutton &&
                                                              ((subcol.hastraderequests &&
                                                                userProperties &&
                                                                userProperties
                                                                  .length) ||
                                                              (subcol.hasactivity &&
                                                                recentTransactions &&
                                                                recentTransactions.length) ? (
                                                                ''
                                                              ) : (
                                                                <WillowButton
                                                                  type="button"
                                                                  variant={
                                                                    subcol.buttonvariant
                                                                  }
                                                                  onClick={() =>
                                                                    dispatch(
                                                                      push(
                                                                        subcol.buttonlink,
                                                                      ),
                                                                    )
                                                                  }
                                                                  className="mb-4"
                                                                  aria-label={
                                                                    subcol.buttonlabel
                                                                  }
                                                                >
                                                                  {
                                                                    subcol.buttonlabel
                                                                  }
                                                                </WillowButton>
                                                              ))}
                                                          </>
                                                        ),
                                                        container: [
                                                          {
                                                            type: 'fluid',
                                                            class: 'px-0',
                                                            rows: [
                                                              {
                                                                columns: [
                                                                  {
                                                                    id:
                                                                      subcol.id &&
                                                                      subcol.id,
                                                                    content: (
                                                                      <>
                                                                        {subcol.haschart &&
                                                                          (subcol.hastraderequests &&
                                                                          userProperties &&
                                                                          userProperties
                                                                            .length ? (
                                                                            <PortfolioPiechart
                                                                              data={
                                                                                assetP ||
                                                                                'false'
                                                                              }
                                                                              className="d-flex justify-content-center"
                                                                            />
                                                                          ) : (
                                                                            <DefaultChart />
                                                                          ))}
                                                                      </>
                                                                    ),
                                                                  },
                                                                ],
                                                              },
                                                              {
                                                                columns: [
                                                                  {
                                                                    id: `portSection6`,
                                                                    content: (
                                                                      <>
                                                                        {subcol.haslist && (
                                                                          <ListLayout
                                                                            className="container"
                                                                            rawdata={[
                                                                              {
                                                                                columns: [
                                                                                  {
                                                                                    content:
                                                                                      '',
                                                                                    rows: [
                                                                                      ...subcol.listdata.map(
                                                                                        list => ({
                                                                                          id:
                                                                                            subcol.id &&
                                                                                            subcol.id,
                                                                                          widths:
                                                                                            subcol.widths &&
                                                                                            JSON.parse(
                                                                                              subcol.widths,
                                                                                            ),
                                                                                          // hr: true,
                                                                                          columns: [
                                                                                            ...list.columns.map(
                                                                                              listcol => ({
                                                                                                content:
                                                                                                  listcol.label,
                                                                                                classes:
                                                                                                  listcol.class,
                                                                                                id: `portfooli-${listcol.id}`,
                                                                                              }),
                                                                                            ),
                                                                                          ],
                                                                                        }),
                                                                                      ),
                                                                                      {
                                                                                        columns: [
                                                                                          {
                                                                                            classes:
                                                                                              'mt-n2',
                                                                                            content: (
                                                                                              <>
                                                                                                {subcol.hastraderequests &&
                                                                                                userProperties &&
                                                                                                userProperties
                                                                                                  .length ? (
                                                                                                  assetP &&
                                                                                                  assetP.map(
                                                                                                    (
                                                                                                      prop,
                                                                                                      i,
                                                                                                    ) => (
                                                                                                      <Row
                                                                                                        className="px-9"
                                                                                                        key={
                                                                                                          i
                                                                                                        }
                                                                                                      >
                                                                                                        <hr />
                                                                                                        <Col className="col-md-7 col-sm-12 px-1 pb-3">
                                                                                                          {
                                                                                                            prop.propertyType
                                                                                                          }
                                                                                                        </Col>
                                                                                                        <Col className="col-md-5 col-sm-12 px-1 pb-3 text-end">
                                                                                                          {prop.assetPerformance.toFixed(
                                                                                                            2,
                                                                                                          )}

                                                                                                          %
                                                                                                        </Col>
                                                                                                      </Row>
                                                                                                    ),
                                                                                                  )
                                                                                                ) : (
                                                                                                  <Col>
                                                                                                    <Row className="px-9">
                                                                                                      <hr />
                                                                                                      <Col className="col-md-7 col-sm-12 pb-3 px-1">
                                                                                                        {locale ===
                                                                                                        'en'
                                                                                                          ? 'Multifamily'
                                                                                                          : 'Plusieurs familles'}
                                                                                                      </Col>
                                                                                                      <Col className="col-md-5 col-sm-12 pb-3 px-1 text-end">
                                                                                                        0%
                                                                                                      </Col>

                                                                                                      <hr />
                                                                                                    </Row>
                                                                                                    <Row className="px-9">
                                                                                                      <Col className="col-md-7 col-sm-12 pb-3 px-1">
                                                                                                        {locale ===
                                                                                                        'en'
                                                                                                          ? 'Retail'
                                                                                                          : 'Vendre au détail'}
                                                                                                      </Col>
                                                                                                      <Col className="col-md-5 col-sm-12 pb-3 px-1 text-end">
                                                                                                        0%
                                                                                                      </Col>

                                                                                                      <hr />
                                                                                                    </Row>
                                                                                                    <Row className="px-9">
                                                                                                      <Col className="col-md-7 col-sm-12 pb-3 px-1">
                                                                                                        {locale ===
                                                                                                        'en'
                                                                                                          ? 'Office'
                                                                                                          : 'Bureau'}
                                                                                                      </Col>
                                                                                                      <Col className="col-md-5 col-sm-12 pb-3 px-1 text-end">
                                                                                                        0%
                                                                                                      </Col>

                                                                                                      <hr />
                                                                                                    </Row>
                                                                                                  </Col>
                                                                                                )}
                                                                                              </>
                                                                                            ),
                                                                                          },
                                                                                        ],
                                                                                      },
                                                                                    ],
                                                                                  },
                                                                                ],
                                                                              },
                                                                            ]}
                                                                          />
                                                                        )}

                                                                        {subcol.haspropertylistdata &&
                                                                        subcol.hastraderequests &&
                                                                        userProperties &&
                                                                        userProperties
                                                                          .length ? (
                                                                          <ListLayout
                                                                            fluid
                                                                            rawdata={[
                                                                              {
                                                                                columns: [
                                                                                  {
                                                                                    content:
                                                                                      '',
                                                                                    rows: [
                                                                                      ...subcol.propertylistdata.map(
                                                                                        (
                                                                                          plist,
                                                                                          i,
                                                                                        ) => {
                                                                                          if (
                                                                                            view ===
                                                                                            'list'
                                                                                          )
                                                                                            return {
                                                                                              id: `plist-${i}`,
                                                                                              hr: true,
                                                                                              columns: [
                                                                                                ...plist.columns.map(
                                                                                                  plistcol => ({
                                                                                                    classes:
                                                                                                      plistcol.class &&
                                                                                                      plistcol.class,
                                                                                                    widths:
                                                                                                      plistcol.widths &&
                                                                                                      JSON.parse(
                                                                                                        plistcol.widths,
                                                                                                      ),
                                                                                                    id:
                                                                                                      plistcol.id,
                                                                                                    content:
                                                                                                      plistcol.label &&
                                                                                                      plistcol.label,
                                                                                                  }),
                                                                                                ),
                                                                                              ],
                                                                                            };
                                                                                          return {
                                                                                            id:
                                                                                              'proplist',
                                                                                            widths:
                                                                                              subcol.widths &&
                                                                                              JSON.parse(
                                                                                                subcol.widths,
                                                                                              ),
                                                                                            hr: false,
                                                                                            columns: [],
                                                                                          };
                                                                                        },
                                                                                      ),

                                                                                      {
                                                                                        ...(subcol.haspropertylistdata &&
                                                                                        view ===
                                                                                          'list'
                                                                                          ? {
                                                                                              columns: [
                                                                                                {
                                                                                                  content: (
                                                                                                    <>
                                                                                                      <div
                                                                                                        style={{
                                                                                                          position:
                                                                                                            'absolute',
                                                                                                          top:
                                                                                                            '-125px',
                                                                                                          right:
                                                                                                            '-52px',
                                                                                                        }}
                                                                                                      >
                                                                                                        <Tooltip
                                                                                                          placement="left"
                                                                                                          size="sm"
                                                                                                          buttonProps={{
                                                                                                            iconOnly: true,
                                                                                                            icon:
                                                                                                              'questionCircle',
                                                                                                            variant:
                                                                                                              'basic-alt',
                                                                                                            iconClass:
                                                                                                              'p-0 text-xdark',
                                                                                                            size:
                                                                                                              'small',
                                                                                                          }}
                                                                                                          message={
                                                                                                            subcol.infobubbletext
                                                                                                          }
                                                                                                        />
                                                                                                      </div>
                                                                                                      <ListLayout
                                                                                                        fluid
                                                                                                        rawdata={[
                                                                                                          ...(userProperties &&
                                                                                                            userProperties.map(
                                                                                                              (
                                                                                                                property,
                                                                                                                i,
                                                                                                              ) => ({
                                                                                                                hr: true,
                                                                                                                columns: [
                                                                                                                  {
                                                                                                                    id: `property-${i}`,
                                                                                                                    content:
                                                                                                                      '',
                                                                                                                    rows: [
                                                                                                                      {
                                                                                                                        columns: [
                                                                                                                          // {
                                                                                                                          //   widths: {
                                                                                                                          //     md: 1,
                                                                                                                          //   },
                                                                                                                          //   // classes:
                                                                                                                          //   //   'px-2',
                                                                                                                          //   content: (
                                                                                                                          //     <>
                                                                                                                          //       <Link
                                                                                                                          //         to={`issue/${property.issueSymbol}`}
                                                                                                                          //         className="m-0 p-0 text-decoration-none border-0"
                                                                                                                          //       >
                                                                                                                          //         <img
                                                                                                                          //           src={
                                                                                                                          //             property.issuerName
                                                                                                                          //           }
                                                                                                                          //           className="img-fluid rounded mb-4"
                                                                                                                          //           alt={`property - ${property.issuerName}`}
                                                                                                                          //         />
                                                                                                                          //       </Link>
                                                                                                                          //     </>
                                                                                                                          //   ),
                                                                                                                          // },
                                                                                                                          // {
                                                                                                                          //   widths: {
                                                                                                                          //     md: 2,
                                                                                                                          //   },
                                                                                                                          //   classes:
                                                                                                                          //     'px-3 text-start',
                                                                                                                          //   content: (
                                                                                                                          //     <>
                                                                                                                          //       {/* <p className="w-100 mb-0 text-greys-dark">
                                                                                                                          //         {
                                                                                                                          //           property.propertyLocation
                                                                                                                          //         }
                                                                                                                          //       </p> */}
                                                                                                                          //       <p className="w-100 mb-0 text-black">
                                                                                                                          //         <Link
                                                                                                                          //           to={`issue/${property.issueSymbol}`}
                                                                                                                          //           className="m-0 p-0 text-decoration-none"
                                                                                                                          //         >
                                                                                                                          //           {
                                                                                                                          //             property.issuerName
                                                                                                                          //           }
                                                                                                                          //         </Link>
                                                                                                                          //       </p>
                                                                                                                          //       <p className="w-100 mb-0 text-greys-dark">
                                                                                                                          //         {formatters.currency(
                                                                                                                          //           property.amount,
                                                                                                                          //         )}
                                                                                                                          //       </p>
                                                                                                                          //     </>
                                                                                                                          //   ),
                                                                                                                          // },
                                                                                                                          {
                                                                                                                            widths: {
                                                                                                                              md: 2,
                                                                                                                            },
                                                                                                                            classes:
                                                                                                                              'px-2 text-xs-start text-md-center',
                                                                                                                            content: (
                                                                                                                              <>
                                                                                                                              <p className="w-100 mb-0 text-greys-dark">
                                                                                                                                {property.issuerName} {property.issueSymbol}
                                                                                                                              </p>
                                                                                                                              </>
                                                                                                                            ),
                                                                                                                          },
                                                                                                                          {
                                                                                                                            widths: {
                                                                                                                              md: 2,
                                                                                                                            },
                                                                                                                            classes:
                                                                                                                              'px-2 text-xs-start text-md-center',
                                                                                                                            content: (
                                                                                                                              <>
                                                                                                                              <p className="w-100 mb-0 text-greys-dark">
                                                                                                                                {property.transactionId}
                                                                                                                              </p>
                                                                                                                              </>
                                                                                                                            ),
                                                                                                                          },
                                                                                                                          {
                                                                                                                            widths: {
                                                                                                                              md: 2,
                                                                                                                            },
                                                                                                                            classes:
                                                                                                                              'px-2 text-xs-start text-md-center',
                                                                                                                            content: (
                                                                                                                              <>
                                                                                                                              <p className="w-100 mb-0 text-greys-dark">
                                                                                                                                {property.settledDate ? moment
                                                                                                                                  .utc(
                                                                                                                                    property.settledDate,
                                                                                                                                  )
                                                                                                                                  .local()
                                                                                                                                  .format(
                                                                                                                                    'll',
                                                                                                                                  ): "Pending"}
                                                                                                                                </p>
                                                                                                                              </>
                                                                                                                            ),
                                                                                                                          },
                                                                                                                          {
                                                                                                                            widths: {
                                                                                                                              md: 2,
                                                                                                                            },
                                                                                                                            classes:
                                                                                                                              'px-2 text-xs-start text-md-center',
                                                                                                                            content: (
                                                                                                                              <>
                                                                                                                              <p className="w-100 mb-0 text-greys-dark">
                                                                                                                                {`$`}{property.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {property.currency}
                                                                                                                              </p>
                                                                                                                              </>
                                                                                                                            ),
                                                                                                                          },
                                                                                                                          {
                                                                                                                            widths: {
                                                                                                                              md: 2,
                                                                                                                            },
                                                                                                                            classes:
                                                                                                                              'px-2 text-xs-start text-md-center',
                                                                                                                            content: (
                                                                                                                              <>
                                                                                                                              <p className="w-100 mb-0 text-greys-dark">
                                                                                                                                {property.shares ? property.shares : "Pending"}
                                                                                                                              </p>
                                                                                                                              </>
                                                                                                                            ),
                                                                                                                          },
                                                                                                                          {
                                                                                                                            widths: {
                                                                                                                              md: 2,
                                                                                                                            },
                                                                                                                            classes:
                                                                                                                              'px-2 text-xs-start text-md-center',
                                                                                                                            content: (
                                                                                                                              <>
                                                                                                                              <p className="w-100 mb-0 text-greys-dark">
                                                                                                                                {getStatusMessage(property.status)}
                                                                                                                              </p>
                                                                                                                              </>
                                                                                                                            ),
                                                                                                                          }
                                                                                                                        ],
                                                                                                                      },
                                                                                                                    ],
                                                                                                                  },
                                                                                                                ],
                                                                                                              }),
                                                                                                            )),
                                                                                                        ]}
                                                                                                      />
                                                                                                    </>
                                                                                                  ),
                                                                                                },
                                                                                              ],
                                                                                            }
                                                                                          : ''),
                                                                                      },
                                                                                    ],
                                                                                  },
                                                                                ],
                                                                              },
                                                                            ]}
                                                                          />
                                                                        ) : (
                                                                          ''
                                                                        )}

                                                                        {view ===
                                                                        'grid' ? (
                                                                          <Row>
                                                                            {subcol.hasgridview &&
                                                                              gridviewData &&
                                                                              gridviewData.map(
                                                                                i => (
                                                                                  <Col
                                                                                    className="ms-3 me-0 ms-md-5 me-md-n4 text-start"
                                                                                    key={
                                                                                      i.id
                                                                                    }
                                                                                    xl={
                                                                                      3
                                                                                    }
                                                                                    md={
                                                                                      5
                                                                                    }
                                                                                    xs={
                                                                                      11
                                                                                    }
                                                                                  >
                                                                                    <Link
                                                                                      to={`/en/trade/${i.symbol}`}
                                                                                      className="text-decoration-none"
                                                                                    >
                                                                                      <Card
                                                                                        width="100%"
                                                                                        size="lg"
                                                                                        tabIndex="0"
                                                                                        className="overflow-visible"
                                                                                      >
                                                                                        <Card.Content>
                                                                                          <div className="position-relative">
                                                                                            <Card.Img
                                                                                              src={`${i.imageLocation}?w=500&h=300&fit=crop`}
                                                                                              alt="property"
                                                                                              className="img-fluid"
                                                                                            />
                                                                                            <Flag
                                                                                              className={`${
                                                                                                i.ipoDetails
                                                                                                  ? 'bg-primary'
                                                                                                  : 'bg-warning'
                                                                                              } position-absolute text-black opacity-75 font-bold top-5 end-5`}
                                                                                              variant="primary"
                                                                                            >
                                                                                              {i.ipoDetails
                                                                                                ? subcol.offeringlabel1 &&
                                                                                                  subcol.offeringlabel1
                                                                                                : subcol.offeringlabel2 &&
                                                                                                  subcol.offeringlabel2}
                                                                                            </Flag>
                                                                                            {typeof i.ownership !==
                                                                                              'undefined' && (
                                                                                              <Flag
                                                                                                className="position-absolute text-black bottom-5 end-5 text-white font-bold"
                                                                                                variant="primary"
                                                                                              >
                                                                                                {`${
                                                                                                  i.ownership
                                                                                                }% ${subcol.ownedlabel &&
                                                                                                  subcol.ownedlabel}`}
                                                                                              </Flag>
                                                                                            )}
                                                                                          </div>

                                                                                          <Card.Body className="border rounded-bottom">
                                                                                            <Card.Body.Left>
                                                                                              <p className="mb-0 text-greys-dark">
                                                                                                {
                                                                                                  i.location
                                                                                                }
                                                                                              </p>
                                                                                              <p className="mb-0 text-black h5 v-10">
                                                                                                {
                                                                                                  i.name
                                                                                                }
                                                                                              </p>
                                                                                              <Card.Price
                                                                                                {...i.ipoDetails}
                                                                                                daysleftlabel={
                                                                                                  subcol.daysleftlabel &&
                                                                                                  subcol.daysleftlabel
                                                                                                }
                                                                                                investedlabel={
                                                                                                  subcol.investedlabel &&
                                                                                                  subcol.investedlabel
                                                                                                }
                                                                                                locale={
                                                                                                  locale
                                                                                                }
                                                                                              />
                                                                                            </Card.Body.Left>
                                                                                            <Card.Body.Right>
                                                                                              {/* <Card.ProgressBar
                                                                                                now={
                                                                                                  i
                                                                                                    .ipoDetails
                                                                                                    .reservedPercentage
                                                                                                }
                                                                                                tag={
                                                                                                  locale ===
                                                                                                  'en'
                                                                                                    ? 'reserved'
                                                                                                    : 'en réserve'
                                                                                                }
                                                                                                aria-label="progress-bar"
                                                                                                className="bg-primary"
                                                                                              /> */}
                                                                                              <div className="position-relative h-100 w-100 d-flex justify-content-end align-items-center">
                                                                                                <img
                                                                                                  className="float-end"
                                                                                                  alt="ipo_acquired"
                                                                                                  src="https://willow.imgix.net/2022/04/ipoacquiredh_small.png?w=87&h=116"
                                                                                                />
                                                                                                <div className="position-absolute d-flex align-items-center top-50 z-index-2">
                                                                                                  <Icon
                                                                                                    variant="Verified"
                                                                                                    color="#fff"
                                                                                                    size="xlarge"
                                                                                                  />
                                                                                                </div>
                                                                                              </div>
                                                                                            </Card.Body.Right>
                                                                                          </Card.Body>
                                                                                        </Card.Content>
                                                                                      </Card>
                                                                                    </Link>
                                                                                  </Col>
                                                                                ),
                                                                              )}
                                                                          </Row>
                                                                        ) : (
                                                                          ''
                                                                        )}

                                                                        <Row>
                                                                          <Col
                                                                            xs={
                                                                              12
                                                                            }
                                                                            md={
                                                                              6
                                                                            }
                                                                          >
                                                                            {subcol.hasactivity &&
                                                                            recentTransactions &&
                                                                            recentTransactions.length ? (
                                                                              <Row>
                                                                                <Col
                                                                                  xs={
                                                                                    12
                                                                                  }
                                                                                >
                                                                                  <div className="bg-white rounded p-5 ms-n3 me-n3 me-md-0 mb-4">
                                                                                    <Row>
                                                                                      <Col
                                                                                        xs={
                                                                                          7
                                                                                        }
                                                                                      >
                                                                                        <p className="font-bold text-black">
                                                                                          {
                                                                                            subcol.activitylabel1
                                                                                          }
                                                                                        </p>
                                                                                      </Col>
                                                                                      <Col
                                                                                        xs={
                                                                                          5
                                                                                        }
                                                                                      >
                                                                                        <span
                                                                                          onClick={() => {
                                                                                            seeAll(
                                                                                              subcol.activitylabel1,
                                                                                              subcol.activityurl,
                                                                                            );
                                                                                          }}
                                                                                          key={
                                                                                            i
                                                                                          }
                                                                                          role="button"
                                                                                          className="text-decoration-underline float-end"
                                                                                        >
                                                                                          {
                                                                                            subcol.activitylabel
                                                                                          }{' '}
                                                                                          <Icon
                                                                                            variant="chevronRight"
                                                                                            size="sm"
                                                                                            color="#4cd683"
                                                                                          />
                                                                                        </span>
                                                                                      </Col>
                                                                                    </Row>
                                                                                    <ListLayout
                                                                                      fluid
                                                                                      rawdata={
                                                                                        recentTransactions && [
                                                                                          ...recentTransactions
                                                                                            .slice(
                                                                                              0,
                                                                                              6,
                                                                                            )
                                                                                            .map(
                                                                                              (
                                                                                                rtr,
                                                                                                i,
                                                                                              ) => ({
                                                                                                columns: [
                                                                                                  {
                                                                                                    id: `rtr - ${i}`,
                                                                                                    content:
                                                                                                      '',
                                                                                                    rows: [
                                                                                                      {
                                                                                                        columns: [
                                                                                                          {
                                                                                                            widths: {
                                                                                                              xs: 7,
                                                                                                              lg: 8,
                                                                                                            },
                                                                                                            content: (
                                                                                                              <>
                                                                                                                <div className="pb-3">
                                                                                                                  <span className="mb-1 text-black p">
                                                                                                                    {`${
                                                                                                                      rtr.type
                                                                                                                        ? rtr.type
                                                                                                                        : ''
                                                                                                                    }
                                                                                                              ${
                                                                                                                rtr.transactionType
                                                                                                                  ? rtr.transactionType
                                                                                                                  : ''
                                                                                                              } -
                                                                                                            ${moment
                                                                                                              .utc(
                                                                                                                rtr.timestamp,
                                                                                                              )
                                                                                                              .local()
                                                                                                              .format(
                                                                                                                'MMMM Do YYYY',
                                                                                                              )}`}
                                                                                                                  </span>{' '}
                                                                                                                  <br />
                                                                                                                  <span className="mb-0 text-greys-dark p">
                                                                                                                    {rtr.state
                                                                                                                      ? rtr.state
                                                                                                                      : ''}
                                                                                                                    {rtr.symbol
                                                                                                                      ? rtr.symbol
                                                                                                                      : ''}
                                                                                                                  </span>
                                                                                                                </div>
                                                                                                              </>
                                                                                                            ),
                                                                                                          },
                                                                                                          {
                                                                                                            widths: {
                                                                                                              xs: 5,
                                                                                                              lg: 4,
                                                                                                            },
                                                                                                            content: (
                                                                                                              <>
                                                                                                                <span className="mb-1 text-greys-dark d-flex justify-content-end p pb-3">
                                                                                                                  {rtr.amount
                                                                                                                    ? formatters.currency(
                                                                                                                        rtr.amount,
                                                                                                                      )
                                                                                                                    : ''}
                                                                                                                  {rtr.price
                                                                                                                    ? formatters.currency(
                                                                                                                        rtr.price,
                                                                                                                      )
                                                                                                                    : ''}
                                                                                                                </span>
                                                                                                              </>
                                                                                                            ),
                                                                                                          },
                                                                                                        ],
                                                                                                      },
                                                                                                    ],
                                                                                                  },
                                                                                                ],
                                                                                              }),
                                                                                            ),
                                                                                        ]
                                                                                      }
                                                                                    />
                                                                                  </div>
                                                                                </Col>
                                                                              </Row>
                                                                            ) : (
                                                                              ''
                                                                            )}
                                                                          </Col>

                                                                          <Col
                                                                            xs={
                                                                              12
                                                                            }
                                                                            md={
                                                                              6
                                                                            }
                                                                          >
                                                                            {subcol.hasactivity &&
                                                                            subcol.hastraderequests &&
                                                                            primaryTradeRequests &&
                                                                            primaryTradeRequests.length ? (
                                                                              <Row>
                                                                                <Col
                                                                                  xs={
                                                                                    12
                                                                                  }
                                                                                >
                                                                                  <div className="ms-n3 ms-md-0 me-n3 bg-white rounded p-5">
                                                                                    <Row>
                                                                                      <Col
                                                                                        xs={
                                                                                          7
                                                                                        }
                                                                                      >
                                                                                        <p className="font-bold text-black">
                                                                                          {
                                                                                            subcol.activitylabel2
                                                                                          }
                                                                                        </p>
                                                                                      </Col>
                                                                                      <Col
                                                                                        xs={
                                                                                          5
                                                                                        }
                                                                                      >
                                                                                        <span
                                                                                          onClick={() => {
                                                                                            seeAll(
                                                                                              subcol.activitylabel2,
                                                                                              subcol.activityurl,
                                                                                            );
                                                                                          }}
                                                                                          key={
                                                                                            i
                                                                                          }
                                                                                          role="button"
                                                                                          className="text-decoration-underline float-end"
                                                                                        >
                                                                                          {
                                                                                            subcol.activitylabel
                                                                                          }{' '}
                                                                                          <Icon
                                                                                            variant="chevronRight"
                                                                                            size="sm"
                                                                                            color="#4cd683"
                                                                                          />
                                                                                        </span>
                                                                                      </Col>
                                                                                    </Row>
                                                                                    <ListLayout
                                                                                      fluid
                                                                                      rawdata={
                                                                                        primaryTradeRequests && [
                                                                                          ...primaryTradeRequests
                                                                                            .slice(
                                                                                              0,
                                                                                              6,
                                                                                            )
                                                                                            .map(
                                                                                              (
                                                                                                tradereq,
                                                                                                i,
                                                                                              ) => ({
                                                                                                columns: [
                                                                                                  {
                                                                                                    id: `tradereq - ${i}`,
                                                                                                    content:
                                                                                                      '',
                                                                                                    rows: [
                                                                                                      {
                                                                                                        columns: [
                                                                                                          {
                                                                                                            widths: {
                                                                                                              xs: 7,
                                                                                                              lg: 8,
                                                                                                            },
                                                                                                            content: (
                                                                                                              <>
                                                                                                                <div className="pb-3">
                                                                                                                  <span className="mb-1 text-black p text-start">
                                                                                                                    Buy
                                                                                                                    Request{' '}
                                                                                                                    -{' '}
                                                                                                                    {moment
                                                                                                                      .utc(
                                                                                                                        tradereq.timeStamp,
                                                                                                                      )
                                                                                                                      .local()
                                                                                                                      .format(
                                                                                                                        'MMMM Do',
                                                                                                                      )}
                                                                                                                  </span>{' '}
                                                                                                                  <br />
                                                                                                                  <span className="mb-0 text-greys-dark p">
                                                                                                                    {`${
                                                                                                                      tradereq.symbol
                                                                                                                    } - ${
                                                                                                                      tradereq.quantity
                                                                                                                    } Unit(s) at ${formatters.currency(
                                                                                                                      tradereq.amount,
                                                                                                                    )}`}
                                                                                                                  </span>
                                                                                                                </div>
                                                                                                              </>
                                                                                                            ),
                                                                                                          },
                                                                                                          {
                                                                                                            widths: {
                                                                                                              xs: 5,
                                                                                                              lg: 4,
                                                                                                            },
                                                                                                            content: (
                                                                                                              <>
                                                                                                                <span className="mb-1 text-greys-dark d-flex justify-content-end p pb-3">
                                                                                                                  <Icon
                                                                                                                    variant="dot"
                                                                                                                    className="text-xlight"
                                                                                                                  />
                                                                                                                  <span className="ps-2">
                                                                                                                    {
                                                                                                                      tradereq.status
                                                                                                                    }
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </>
                                                                                                            ),
                                                                                                          },
                                                                                                        ],
                                                                                                      },
                                                                                                    ],
                                                                                                  },
                                                                                                ],
                                                                                              }),
                                                                                            ),
                                                                                        ]
                                                                                      }
                                                                                    />
                                                                                  </div>
                                                                                </Col>
                                                                              </Row>
                                                                            ) : subcol.hasactivity &&
                                                                              subcol.hastraderequests &&
                                                                              recentTransactions &&
                                                                              recentTransactions.length ===
                                                                                0 &&
                                                                              primaryTradeRequests &&
                                                                              primaryTradeRequests.length ===
                                                                                0 ? (
                                                                              ''
                                                                            ) : (
                                                                              subcol.hasactivity &&
                                                                              subcol.hastraderequests &&
                                                                              recentTransactions &&
                                                                              recentTransactions.length !==
                                                                                0 &&
                                                                              primaryTradeRequests &&
                                                                              primaryTradeRequests.length ===
                                                                                0 && (
                                                                                <Row>
                                                                                  <Col
                                                                                    xs={
                                                                                      12
                                                                                    }
                                                                                  >
                                                                                    <div className="ms-n3 ms-md-0 me-n3 bg-white rounded p-5">
                                                                                      <Row>
                                                                                        <Col
                                                                                          xs={
                                                                                            12
                                                                                          }
                                                                                          md={
                                                                                            6
                                                                                          }
                                                                                        >
                                                                                          <p className="font-bold text-black">
                                                                                            {
                                                                                              subcol.activitylabel2
                                                                                            }
                                                                                          </p>
                                                                                        </Col>
                                                                                      </Row>
                                                                                      <Row>
                                                                                        <Col
                                                                                          xs={
                                                                                            12
                                                                                          }
                                                                                        >
                                                                                          <p className="text-black">
                                                                                            No
                                                                                            recent
                                                                                            Trade
                                                                                            Requests
                                                                                          </p>
                                                                                        </Col>
                                                                                      </Row>
                                                                                    </div>
                                                                                  </Col>
                                                                                </Row>
                                                                              )
                                                                            )}
                                                                          </Col>
                                                                        </Row>
                                                                      </>
                                                                    ),
                                                                  },
                                                                ],
                                                              },
                                                            ],
                                                          },
                                                        ],
                                                      }
                                                    : subcol.hasstep && {
                                                        widths:
                                                          subcol.widths &&
                                                          JSON.parse(
                                                            subcol.widths,
                                                          ),
                                                        class:
                                                          'my-4 my-lg-7 px-5 col-md-12',
                                                        id:
                                                          subcol.stepid &&
                                                          subcol.stepid,
                                                        content: (
                                                          <>
                                                            {parseInt(
                                                              subcol.stepid,
                                                            ) < 5 && (
                                                              <>
                                                                {folioStatus <
                                                                  4 && (
                                                                  <>
                                                                    <div
                                                                      className={`${
                                                                        folioStatus >=
                                                                        subcol.stepid
                                                                          ? 'step-border-complete bg-primary border-2 border-primary'
                                                                          : 'step-border'
                                                                      }`}
                                                                    >
                                                                      <p className="h5">
                                                                        {
                                                                          subcol.stepid
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                    <p
                                                                      className={`${
                                                                        subcol.partofbanner
                                                                          ? 'h4 mt-2 mb-3 h-25'
                                                                          : ''
                                                                      }`}
                                                                    >
                                                                      {
                                                                        subcol.header
                                                                      }
                                                                    </p>
                                                                    <p className="pe-2">
                                                                      {
                                                                        subcol.blurb
                                                                      }
                                                                    </p>
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        ),
                                                      },
                                                ),
                                                ...subrow.columns.map(
                                                  subcol =>
                                                    subcol.hasareachart &&
                                                    folioStatus === 4 && {
                                                      widths:
                                                        subcol.widths &&
                                                        JSON.parse(
                                                          subcol.widths,
                                                        ),
                                                      class: subcol.class,
                                                      id: subcol.id,
                                                      content: (
                                                        <>
                                                          {/* {subcol.infobubble && (
                                                            <>
                                                              <div className="position-absolute end-5 bottom-0">
                                                                <Tooltip
                                                                  placement="left"
                                                                  size="sm"
                                                                  buttonProps={{
                                                                    iconOnly: true,
                                                                    icon:
                                                                      'questionCircle',
                                                                    variant:
                                                                      'basic-alt',
                                                                    iconClass:
                                                                      'p-0 text-xdark',
                                                                    size:
                                                                      'small',
                                                                  }}
                                                                  message={
                                                                    subcol.infobubbletext
                                                                  }
                                                                />
                                                              </div>
                                                            </>
                                                          )} */}

                                                          {/* <img
                                                            src={
                                                              subcol.graphimage
                                                            }
                                                            alt="graph"
                                                            className="w-100 img-fluid mt-n5"
                                                          /> */}

                                                          {/* <PortfolioAreachart /> */}
                                                        </>
                                                      ),
                                                    },
                                                ),
                                              ],
                                          })),
                                        ],
                                      },
                                    ],
                                  })),
                                ],
                              })),
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        ))}

      {pagedata.wpcontent.toast && (
        <>
          <Container
            style={{
              position: 'fixed',
              left: 15,
              // top: 55,
              zIndex: 3000,
            }}
          >
            <Toast
              autohide
              priority="low"
              show={pagedata.wpcontent.toast}
              delay={3000}
              message={pagedata.wpcontent.toast}
              onClose={() => dispatch(setPorfolioPageToast(false))}
            />
          </Container>
        </>
      )}

      {pagedata.wpcontent.data && footerState && <Footer rawdata={footerState.data} locale={locale} />}
    </>
  );
};

export default {
  component: Portfolio,
};
