/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import produce from 'immer';
import {
  LOAD_ACCOUNT_LAYOUT,
  LOAD_ACCOUNT_LAYOUT_FAILURE,
  LOAD_ACCOUNT_LAYOUT_SUCCESS,
  SET_ERROR_MESSAGE,
  SET_MODAL_ERROR_MESSAGE,
  SET_TAB,
  STORE_FORM_DATA,
  TOGGLE_CAPSULE,
  TOGGLE_CREDENTIALS_MODAL,
  TOGGLE_VERIFICATION_MODAL,
  SET_TOAST,
  SET_FLINKS_MOODAL,
  SET_FLINKS_LOADER,
  SET_TRANSFER_FUNDS_LOADER,
  TOGGLE_SIDEPANEL,
  TOGGLE_DOWNLOAD,
  LOAD_USER_DOCUMENTS,
  SET_INITIALVALUES,
  SET_CUSTOMER_TYPE
} from './constants';
export const initialState = {
  data: false,
  error: false,
  loading: true,
  errorMessage: false,
  activeTab: false,
  activeCapsule: false,
  verificationModal: false,
  verificationCode: false,
  formData: false,
  toast: false,
  flinksModal: false,
  flinksLoader: false,
  transferFundsLoader: false,
  sidepanel: false,
  sidepanelContent: false,
  documents: false,
  document: false,
  initialValues: false,
  customerType: false
};
const accountPageReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_CUSTOMER_TYPE:
      draft.customerType = action.customerType;
      break;
    case SET_INITIALVALUES:
      draft.initialValues = action.initialValues;
      break;
    case STORE_FORM_DATA:
      draft.formData = action.formData;
      break;
    case LOAD_USER_DOCUMENTS:
      draft.documents = action.documents;
      break;
    case TOGGLE_DOWNLOAD:
      draft.document = action.document;
      break;
    case TOGGLE_SIDEPANEL:
      draft.sidepanel = !draft.sidepanel;
      draft.sidepanelContent = action.sidepanelContent;
      break;
    case SET_FLINKS_MOODAL:
      draft.flinksModal = action.flinksModal;
      break;
    case SET_FLINKS_LOADER:
      draft.flinksLoader = action.flinksLoader;
      break;
    case SET_TRANSFER_FUNDS_LOADER:
      draft.transferFundsLoader = action.transferFundsLoader;
      break;
    case SET_TOAST:
      draft.toast = action.toast;
      break;
    case TOGGLE_VERIFICATION_MODAL:
      draft.verificationModal = action.verificationModal;
      draft.modalErrorMessage = null;
      break;
    case SET_MODAL_ERROR_MESSAGE:
      draft.modalErrorMessage = action.error;
      break;
    case TOGGLE_CREDENTIALS_MODAL:
      draft.credentialsModal = action.credentialsModal;
      draft.modalErrorMessage = null;
      break;
    case SET_ERROR_MESSAGE:
      draft.errorMessage = action.error;
      break;
    case LOAD_ACCOUNT_LAYOUT:
      draft.loading = true;
      draft.error = false;
      break;
    case LOAD_ACCOUNT_LAYOUT_SUCCESS:
      draft.data = action.data;
      draft.loading = false;
      draft.error = false;
      break;
    case LOAD_ACCOUNT_LAYOUT_FAILURE:
      draft.data = false;
      draft.loading = false;
      draft.error = true;
      break;
    case SET_TAB:
      draft.activeTab = action.key;
      //draft.activeCapsule = false;
      break;
    case TOGGLE_CAPSULE:
      draft.activeCapsule = action.activeCapsule;
      break;
  }
}, initialState);

export default accountPageReducer;
