/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import React from 'react';
import Input from 'components/Form/Input';
import ImageUpload from 'components/Form/ImageUpload';
import Select from 'components/Form/Select';
import Check from 'components/Form/Check';
import NumberFormat from 'react-number-format';
const renderField = ({
  input,
  type,
  meta: { touched, error, warning },
  maxLength,
  ...props
}) => {
  // eslint-disable-next-line prefer-const
  if (type === 'dropdown' || type === 'DateofBirth') {
    return (
      <Select
        {...input}
        {...props}
        defaultValue={props.defaultValue ? props.defaultValue : input.value}
        value={props.defaultValue ? props.defaultValue : input.value}
        helperMessage={touched && ((error && error) || (warning && warning))}
        status={touched ? (error && 'error') || (warning && 'warning') : 'none'}
      />
    );
  }
  if (type === 'phone') {
    return (
      <NumberFormat
        customInput={Input}
        {...props}
        {...input}
        type={type}
        status={touched ? (error && 'error') || (warning && 'warning') : 'none'}
        helperMessage={touched && ((error && error) || (warning && warning))}
        format="+1 (###) ###-####"
        mask="_"
      />
    );
  }
  if (type === 'currency') {
    return (
      <NumberFormat
        customInput={Input}
        {...props}
        {...input}
        type={type}
        status={touched ? (error && 'error') || (warning && 'warning') : 'none'}
        helperMessage={touched && ((error && error) || (warning && warning))}
        thousandsGroupStyle="thousand"
        decimalSeparator="."
        displayType="input"
        thousandSeparator
        allowNegative={false}
        decimalScale={props.decimalscale ? props.decimalscale : 0}
        prefix="$"
      />
    );
  }
  if (type === 'percentage') {
    return (
      <Input
        {...props}
        {...input}
        percentage={input.value}
        type={"number"}
        status={touched ? (error && 'error') || (warning && 'warning') : 'none'}
        helperMessage={touched && ((error && error) || (warning && warning))}
        prefix="%"
      />
    );
  }
  if (input.name === 'postalCode') {
    return (
      <Input
        {...props}
        {...input}
        postalCode={input.value.toUpperCase()}
        type={type}
        status={touched ? (error && 'error') || (warning && 'warning') : 'none'}
        helperMessage={touched && ((error && error) || (warning && warning))}
      />
    );
  }
  if (type === 'file') {
    delete input.value;
    return (
      <div>
        <ImageUpload
          {...props}
          {...input}
          status={
            touched ? (error && 'error') || (warning && 'warning') : 'none'
          }
          helperMessage={touched && ((error && error) || (warning && warning))}
        />
      </div>
    );
  }
  if (type === 'checkbox' || type === 'radio') {
    const { checked, value, ...someInputValue } = input;
    return (
      <>
        <Check
          type={type}
          id={props.fieldid}
          value={props.radiovalue ? props.radiovalue : props.label}
          checked={
            type === 'radio'
              ? props.initialradiovalue === props.radiovalue && true
              : value
          }
          label={props.label}
          {...someInputValue}
          {...props}
          helperMessage={touched && ((error && error) || (warning && warning))}
          status={
            touched ? (error && 'error') || (warning && 'warning') : 'none'
          }
        />
        {props.radiodescription && props.label === props.initialradiovalue && (
          <p className="w-100 my-2 ms-5 text-greys-dark">
            {props.radiodescription}
          </p>
        )}
      </>
    );
  }
  return (
    <Input
      {...props}
      {...input}
      type={type}
      status={touched ? (error && 'error') || (warning && 'warning') : 'none'}
      helperMessage={touched && ((error && error) || (warning && warning))}
    />
  );
};

export default renderField;
